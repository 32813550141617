import { FiAlertOctagon } from "react-icons/fi"
import { MdCheck } from "react-icons/md"

export function GetStudentStatus(status?: "active" | "blocked") {
    if (!status) return studentStatus.null

    switch (status) {
        case "active":
            return studentStatus.active
        case "blocked":
            return studentStatus.blocked
    }
}

export const studentStatus = {
    active: {
        label: "ATIVO",
        icon: MdCheck,
        color: "green",
    },
    blocked: {
        label: "BLOQUEADO",
        icon: MdCheck,
        color: "darkorange",
    },
    null: {
        label: "N/A",
        icon: FiAlertOctagon,
        color: "grey",
    },
}
