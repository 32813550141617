import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex: 1 1;
`

export const TemplatesList = styled.div`
    flex: 1;
    flex-direction: column;

    ul {
        flex-direction: column;

        li {
            height: 45px;
            border-radius: 5px;
            justify-content: space-between;
            align-items: center;

            .title {
                margin-left: 5px;
                font-weight: 600;
                font-size: small;
            }

            .subtitle {
                margin-left: 5px;
                font-size: x-small;
                font-weight: 400;
                color: #888;
                text-transform: uppercase;
            }

            .date {
                font-weight: bold;
                font-size: 12px;
            }

            margin-bottom: 5px;
            padding: 5px;
            background-color: #fff;
            border: 1px solid #ccc;
            cursor: pointer;
        }
    }
`

export const TemplateContent = styled.div``
