import React, { useEffect } from "react"
import { Backdrop, Panel } from "./styles"
import StudentsList, { StudentList } from "src/screens/students-list"
import { useStudent } from "src/contexts/student"
import ReactDOM from "react-dom"
import { useLocation, useNavigate } from "react-router-dom"

// import { Container } from './styles';

const StudentsModal: React.FC<{
    onSelect?: (std: StudentList) => void
    active: boolean
    setActive: (value: boolean) => void
}> = ({ onSelect, active, setActive }) => {
    const student = useStudent()
    const root = document.getElementById("root")

    const nav = useNavigate()

    const loc = useLocation()

    useEffect(() => {
        student.blurContent(active)
    }, [active])

    function handleSelect(std: StudentList) {
        if (onSelect != undefined) {
            onSelect(std)
            setActive(false)
        } else {
            //alert(loc.pathname)

            try {
                student.onSetStudent(std.id)
            } finally {
                setActive(false)
            }

            if (!loc.pathname.includes("/student")) nav(`/student`)
        }

        student.blurContent(false)
    }

    if (root === null || !active) return <></>

    return ReactDOM.createPortal(
        <Backdrop>
            <Panel className="flex column">
                <StudentsList
                    dismiss={() => setActive(false)}
                    onSelect={handleSelect}
                />
            </Panel>
        </Backdrop>,
        root
    )
}

export default StudentsModal
