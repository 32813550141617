import React, { useState } from "react"
import styled from "styled-components"

// import { Container } from './styles';

interface SelectButtonProps {
    title?: string
    buttons: {
        title: string
        value?: string
    }[]
    buttonWidth?: number
    height?: number
    unselectable?: boolean
    defaultValue?: string

    disabled?: boolean

    onSelect(value: string): void
}

const Wrapper = styled.div`
    button:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right: none;
    }

    button:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: none;
    }

    button {
        background-color: #fff;
        border: 1px solid #777;
        align-items: center;
        justify-content: center;
        :hover {
            background-color: #0001;
        }

        cursor: pointer;

        > p {
            font-weight: 600;
            padding-inline: 5px;
        }
    }

    .selected {
        background-color: #0002;
    }
`

const SelectButtons: React.FC<SelectButtonProps> = (props) => {
    const [selected, setSelected] = useState(props.defaultValue ?? "")

    function select(e: React.MouseEvent<HTMLButtonElement>) {
        if (props.disabled) return
        const { name } = e.currentTarget

        const _name = props.unselectable && selected === name ? "" : name

        setSelected(_name)
        props.onSelect(_name)
    }

    return (
        <div style={{ flexDirection: "column" }}>
            {props.title && (
                <p style={{ fontSize: 12, paddingBlock: 5 }}>{props.title}</p>
            )}
            <Wrapper style={{ height: props.height ?? 40 }}>
                {props.buttons.map((e, i) => {
                    const value = e.value ?? e.title
                    return (
                        <button
                            name={value}
                            className={selected === value ? "selected" : ""}
                            key={"selb" + i}
                            onClick={select}
                            style={{ width: props.buttonWidth }}
                        >
                            <p>{e.title}</p>
                        </button>
                    )
                })}
            </Wrapper>
        </div>
    )
}

export default SelectButtons
