import React, { useEffect, useState } from "react"
import { TemplatesB } from ".."
import { BiChevronRight, BiSearch } from "react-icons/bi"
import api from "src/rest/api"
import {
    Browser,
    SearchBox,
    List,
    ExerciseListItem,
    DateLabel,
} from "../styles"
import moment from "moment"
import { TbCalendarMinus } from "react-icons/tb"
import {
    Workout,
    WorkoutTypeString,
} from "../../../../fithub-shared/interfaces/workout"

// import { Container } from './styles';

const WorkoutsTemplates: React.FC<TemplatesB> = (props) => {
    const [list, setList] = useState<Workout[]>([])
    const [search, setSearch] = useState("")

    useEffect(() => {
        GetList()
    }, [])

    const filtered =
        search.length > 0 && list.length > 0
            ? list.filter((e) =>
                  e.title.toUpperCase().includes(search.toUpperCase())
              )
            : list

    function Search(t: string) {
        setSearch(t)
    }

    async function GetList() {
        await api
            .post("workout-templates/list")
            .then((res) => {
                setList(res.data as Workout[])
            })
            .catch((err) => alert(err))
    }

    function Select(str: string, id: string) {
        props.onSelect(str, id)
        props.onDismis(false)
    }
    return (
        <Browser>
            <SearchBox>
                <BiSearch />
                <input
                    id="search-box"
                    autoFocus
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => Search(e.target.value)}
                    placeholder="Pesquisar Treino..."
                />
            </SearchBox>

            <List>
                {filtered.map((element, index) => {
                    return (
                        <ExerciseListItem
                            key={index}
                            onClick={() => Select(element.title, element.id)}
                        >
                            <div
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    fontSize: 12,
                                }}
                            >
                                <p>{WorkoutTypeString(element.type)}</p>

                                <BiChevronRight />
                                <p>{element.title}</p>
                            </div>

                            <DateLabel>
                                <p>
                                    {moment(element.created_at).format(
                                        "DD/MM/YYYY"
                                    )}
                                </p>

                                <TbCalendarMinus size={16} />
                            </DateLabel>
                        </ExerciseListItem>
                    )
                })}
            </List>
        </Browser>
    )
}

export default WorkoutsTemplates
