import api from "src/rest/api"
import { Anamnese } from "src/modules/fithub-shared/interfaces/anamnese"
import {
    UseMutationResult,
    useQueryClient,
    useMutation,
    useQuery,
} from "react-query"
import mostrarAlerta from "src/components/utils/alert"

export const useGetAnamnese = () => {
    return useQuery(
        "anamnese",
        () =>
            api
                .post("anamnese/get")
                .then((res) => res.data as Anamnese)
                .catch(() => undefined),
        {
            staleTime: 60 * 1000,
            cacheTime: 3600 * 1000,
        }
    )
}

export const useAnamneseMutation = (): UseMutationResult<
    void,
    Error,
    Anamnese,
    unknown
> => {
    const queryClient = useQueryClient()

    return useMutation<void, Error, Anamnese, unknown>(
        async (anamnese) => {
            await api.post("/anamnese/update", anamnese)
        },
        {
            onMutate: async (variables) => {
                return variables
            },
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                mostrarAlerta("Salvo!")
                queryClient.refetchQueries("anamnese")
            },
        }
    )
}
