const convertYouTubeLinkToEmbed = (url: string): string => {
    const videoIdMatch = url.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|user\/\S*?\/\S*?\/\S*?\/))([\w-]{11})/
    )

    if (videoIdMatch && videoIdMatch[1]) {
        const videoId = videoIdMatch[1]

        return `https://www.youtube.com/embed/${videoId}`
    }

    throw new Error("Invalid YouTube URL")
}

export const convertEmbedToYouTubeLink = (embedUrl: string): string => {
    const videoIdMatch = embedUrl.match(
        /https:\/\/www\.youtube\.com\/embed\/([\w-]{11})/
    )

    if (videoIdMatch && videoIdMatch[1]) {
        const videoId = videoIdMatch[1]
        return `https://www.youtube.com/watch?v=${videoId}`
    }

    throw new Error("Invalid YouTube embed URL")
}

export default convertYouTubeLinkToEmbed
