import React, { CSSProperties, InputHTMLAttributes, useState } from "react"
import { IconType } from "react-icons"
import { Area } from "./styles"
import { FiEye, FiEyeOff } from "react-icons/fi"
import { filterProps } from "./functions"
import styled from "styled-components"

// import { Container } from './styles';

export const VerticalWrapper = styled.div<{ width?: number | string }>`
    flex: 1;
    width: ${(p) => p.width && p.width};
    height: 45;
    flex-shrink: 0;
    flex-direction: column;
`

interface InputProps {
    error?: boolean
    style?: CSSProperties
    icon?: IconType
    width?: number | string
    radius?: string
    selectOnFocus?: boolean
    minLength?: number
    backgroundColor?: string
    inputProps?: InputHTMLAttributes<HTMLInputElement>

    onChangeText?: (value: string) => void
    onEndText?: (t: string) => void

    hint?: string
    alignRight?: boolean
}

const Inputfield: React.FC<InputProps> = (props) => {
    const [error, setError] = useState(props.error)
    const [reveal, setReveal] = useState(false)

    const Reveal = () => {
        setReveal(!reveal)
    }

    const RevealType = () => {
        if (props.inputProps?.type === "password") {
            return reveal ? "reveal" : "password"
        } else {
            return props.inputProps?.type
        }
    }

    function preventMax(e: React.KeyboardEvent<HTMLInputElement>) {
        const { value, maxLength } = e.currentTarget

        if (maxLength === -1) return

        const sel = window.getSelection()?.type === "Range"

        if (
            value.length >= maxLength &&
            e.key.length === 1 && // Somente caracteres imprimíveis
            !e.ctrlKey &&
            !sel
        ) {
            e.preventDefault()
        }
    }

    function _OnChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (props.inputProps?.onChange) props.inputProps.onChange(e)
        if (props.onChangeText) props.onChangeText(e.target.value)
    }

    function _OnBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
        if (props.minLength) {
            setError(
                e.target.value.length > 0 &&
                    e.target.value.length < props.minLength
            )
        }

        if (props.onEndText) props.onEndText(e.target.value)
    }

    function _OnFocus(e: React.FocusEvent<HTMLInputElement, Element>) {
        if (props.selectOnFocus) e.target.select()
    }

    return (
        <VerticalWrapper width={props.width}>
            {props.hint && (
                <p
                    style={{
                        width: "100%",
                        fontSize: 12,
                        paddingBottom: 5,
                    }}
                >
                    {props.hint}
                </p>
            )}
            <Area
                tabIndex={-1}
                radius={props.radius}
                style={{
                    ...{
                        flexDirection: "row",
                        paddingLeft: props.icon ? 0 : 5,
                        opacity: props.inputProps?.disabled ? 0.5 : 1,
                        backgroundColor: props.backgroundColor
                            ? props.backgroundColor
                            : "#fff",
                        borderColor: error ? "red" : "#ccc",
                        flexShrink: 0,
                    },
                    ...props.style,
                }}
            >
                {props.icon && (
                    <div style={{ marginInline: 5 }}>
                        <props.icon color={"#444"} size={18} />
                    </div>
                )}
                <input
                    {...props.inputProps}
                    style={{
                        ...props.inputProps?.style,
                        paddingInline: 5,
                        fontSize: props.style?.fontSize,
                    }}
                    type={RevealType()}
                    onChange={_OnChange}
                    onKeyDown={preventMax}
                    onBlur={_OnBlur}
                    onFocus={_OnFocus}
                />
                {props.inputProps?.type === "password" && (
                    <button
                        tabIndex={-1}
                        style={{
                            padding: 5,
                            borderRadius: 25,
                            cursor: "pointer",
                        }}
                        onClick={Reveal}
                    >
                        {reveal ? <FiEyeOff /> : <FiEye />}
                    </button>
                )}
            </Area>
        </VerticalWrapper>
    )
}

export default Inputfield
