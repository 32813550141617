import React from "react"
import styled from "styled-components"
import { Card } from ".."
import { CgChevronRight } from "react-icons/cg"
import { useNavigate } from "react-router-dom"

const Title = styled.h4`
    width: fit-content;
    color: var(--preto);
    font-weight: 600;
`

const Block = styled.div`
    display: flex;
    flex-direction: column;
`

const Item = styled.button`
    display: flex;
    padding: 10px;
    border-radius: 5px;

    cursor: pointer;

    :active {
        opacity: 0.6;
    }

    height: 45px;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    :not(:first-child) {
        margin-top: 10px;
    }

    h2 {
        font-size: 14px;
        width: 100%;
        font-weight: 800;
        color: #4a4a4a;
    }

    p {
        font-size: 10px;
        width: 100%;
        color: #777;
        font-weight: 600;
    }

    background-color: var(--panel);
`

const ToolsCard: React.FC = () => {
    const navigate = useNavigate()

    return (
        <Card style={{ justifyContent: "center" }}>
            <Block
                style={{
                    flex: 1,
                    alignItems: "center",
                }}
            >
                <Title>Ferramentas de Consultoria</Title>
                <Block
                    style={{
                        width: "100%",
                        flex: 1,
                        justifyContent: "center",
                    }}
                >
                    <Item onClick={() => navigate("tools/plans")}>
                        <div>
                            <div style={{ flexDirection: "column" }}>
                                <h2>Planos Template</h2>
                                <p>Biblioteca de Planos de Treino</p>
                            </div>
                        </div>
                        <CgChevronRight size={20} color="var(--preto)" />
                    </Item>
                    <Item onClick={() => navigate("tools/workout")}>
                        <div>
                            <div style={{ flexDirection: "column" }}>
                                <h2>Treinos Template</h2>
                                <p>Biblioteca Treinos</p>
                            </div>
                        </div>
                        <CgChevronRight size={20} color="var(--preto)" />
                    </Item>
                    <Item onClick={() => navigate("tools/exercises")}>
                        <div>
                            <div style={{ flexDirection: "column" }}>
                                <h2>Exercícios Template</h2>
                                <p>Biblioteca de Exercícios</p>
                            </div>
                        </div>
                        <CgChevronRight size={20} color="var(--preto)" />
                    </Item>
                    <Item onClick={() => navigate("tools/anamnese")}>
                        <div>
                            <div style={{ flexDirection: "column" }}>
                                <h2>Fomulário Anamnese</h2>
                                <p>Criar ou Vincular anamnese</p>
                            </div>
                        </div>
                        <CgChevronRight size={20} color="var(--preto)" />
                    </Item>
                </Block>
            </Block>
        </Card>
    )
}

export default ToolsCard
