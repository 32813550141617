import React, { ReactNode, createContext, useContext, useState } from "react"
import api from "src/rest/api"
import Dialog from "src/components/dialog"
import { AssessmentClass } from "src/modules/fithub-shared/anthropometry/assessment"
import { useQuery, UseQueryResult } from "react-query"
import { useStudent } from "src/contexts/student"
import { useDialog } from "src/contexts/dialog-context"

interface AssessmentContextType {
    contentData?: AssessmentClass
    loadAssessment(id: string): Promise<AssessmentClass | null>
    save(id?: AssessmentClass): Promise<void>
    setUnsaved(): void
    saved: boolean
    list: UseQueryResult<AssessmentClass[], unknown>
    handleDelete: (action: () => void) => void
}

interface Props {
    children: ReactNode
}

const AssessmentContext = createContext<AssessmentContextType>(
    {} as AssessmentContextType
)

export const AssessmentContextProvider: React.FC<Props> = ({ children }) => {
    // Defina o estado ou dados que deseja fornecer através do contexto

    const { student } = useStudent()

    const dl = useDialog()

    function handleDelete(del: () => void) {
        dl.openDialog("Excluir", "Deseja excluir esta avaliação?", () => {
            del()
            dl.closeDialog()
        })
    }

    const [saved, setSaved] = useState(true)
    const [contentData, setSelected] = useState<AssessmentClass>()

    const list = useQuery(["assessmentList", student?.id], () =>
        api
            .post<AssessmentClass[]>("/assessment/list", {
                user_id: student?.id,
            })
            .then((res) => res.data)
            .catch((err) => {
                console.error(err)
                return [] as AssessmentClass[]
            })
    )

    function loadAssessment(id: string) {
        return api
            .post<AssessmentClass>("assessment/get", { id: id })
            .then((res) => {
                setSelected(res.data)
                setSaved(true)
                return res.data
            })
            .catch((err) => {
                alert("Não foi possível carregar avaliação!\n\n" + err)
                return null
            })
    }

    function save(assessment: AssessmentClass) {
        return api
            .post("/assessment/update", assessment ?? contentData)
            .then(() => setSaved(true))
            .catch((err) =>
                alert("Não foi possível salvar avaliação!\n\n" + err)
            )
    }

    function setUnsaved() {
        if (saved) setSaved(false)
    }

    return (
        <AssessmentContext.Provider
            value={{
                list,
                handleDelete,
                contentData,
                loadAssessment,
                save,
                setUnsaved,
                saved,
            }}
        >
            {children}
        </AssessmentContext.Provider>
    )
}

export const useAssessmentContext = () => {
    return useContext(AssessmentContext)
}
