import React from "react"
import {
    Wrapper,
    PlanDetails,
    PlanTitle,
    PlanItem,
    Section,
    Subscribe,
    OutlineButton,
} from "../styles"
import { useQuery } from "react-query"
import api from "src/rest/api"
import { useOverlay } from "../context"
import TrainerPlans from "src/modules/TrainerCheckout/TrainerPlans"
import PlansOver from "src/modules/TrainerCheckout"
import { loadStripe } from "@stripe/stripe-js"
import { FiAlertCircle } from "react-icons/fi"
import { ThreeDots } from "react-loader-spinner"
import { useDialog } from "src/contexts/dialog-context"

interface SubscriptionData {
    active: boolean
    data?: {
        id: string
        plan: {
            id: string
            amount: number
            currency: string
        }
        current_period_end: string
        auto_renew: boolean
    }
}

const CurrentPlan: React.FC = () => {
    const { data, isLoading } = useQuery("userplan", () =>
        api
            .post<SubscriptionData>("/stripe/verify")
            .then((res) => res.data)
            .catch(() => {
                alert("Não foi possível carregar informações de faturamento!")
            })
    )

    const over = useOverlay()

    const dialog = useDialog()

    function ActivateOverlay() {
        over.setShow(<PlansOver />)
    }

    function RequestCancel() {
        dialog.openDialog(
            "Cancelar Assinatura",
            "Para cancelar a assinatura BETA, entre em contato com os Administradores da plataforma!",
            undefined,
            "OK",
            true
        )
    }

    function InfoComponent() {
        if (isLoading) {
            return <ThreeDots />
        }

        if (data && data.active)
            return (
                <PlanDetails>
                    <PlanItem>
                        <strong>Plano ID: </strong>
                        <p style={{ paddingRight: 5 }}>{data.data?.plan.id}</p>
                    </PlanItem>
                    <PlanItem>
                        <strong>ID Assinatura: </strong>
                        <p style={{ paddingRight: 5 }}>{data.data?.id}</p>
                    </PlanItem>
                    <PlanItem>
                        <strong>Valor:</strong>
                        <p>
                            {data.data?.plan.amount}
                            {data.data?.plan.currency.toUpperCase()}
                        </p>
                    </PlanItem>
                    <PlanItem>
                        <strong>Próximo ciclo:</strong>
                        <p>
                            {data &&
                                data.data &&
                                new Date(
                                    data.data?.current_period_end
                                ).toLocaleDateString()}
                        </p>
                    </PlanItem>
                    <PlanItem>
                        <strong>Renovação automática:</strong>
                        <p>{data.data?.auto_renew ? "Ativa" : "Inativa"}</p>
                    </PlanItem>
                </PlanDetails>
            )

        return (
            <PlanDetails
                style={{
                    width: "100%",
                    margin: 0,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <FiAlertCircle />
                <p>Você não possui uma Assinatura ativa.</p>
                <Subscribe onClick={ActivateOverlay}>Assine Já</Subscribe>
            </PlanDetails>
        )
    }

    return (
        <Wrapper>
            <PlanTitle>Seu Plano</PlanTitle>
            <h4>Informações do seu plano atual:</h4>

            <Section style={{ marginTop: 15 }}>
                <InfoComponent />
            </Section>

            {data && data.active ? (
                <div style={{ paddingBlock: 10 }}>
                    <OutlineButton onClick={RequestCancel} color="#f00">
                        Cancelar Assinatura
                    </OutlineButton>
                </div>
            ) : (
                <></>
            )}
        </Wrapper>
    )
}

export default CurrentPlan
