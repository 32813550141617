import styled from "styled-components"

export const ListingDiv = styled.div.attrs({
    class: "flex",
})`
    flex-direction: column;
    flex: 1;
    width: 100%;
`

export const List = styled.ul`
    flex: 1 0;
    flex-direction: column;
    padding: 10px;

    overflow-y: auto;

    .li {
        position: relative;
        background-color: #04aa6d;
        border: none;
        font-size: 28px;
        color: #ffffff;
        padding: 20px;
        width: 200px;
        text-align: center;
        transition-duration: 0.4s;
        text-decoration: none;
        overflow: hidden;
        cursor: pointer;
        border-radius: 8px;
    }
`

export const ExcItem = styled.li<{ selected: boolean }>`
    position: relative;
    background-color: ${(p) => (p.selected ? "#eeeeee" : "white")};
    border-radius: 4px;
    border: 1px solid #ddd;

    border-color: ${(p) => (p.selected ? "#000" : "#ddd")};

    justify-content: space-between;
    padding: 8px;
    align-items: center;

    > h4 {
        font-weight: 600;
        font-size: 16px;
    }

    :not(:last-child) {
        margin-bottom: 10px;
    }

    > div {
        flex-direction: row;
        align-items: center;
    }

    cursor: pointer;
`
