import styled from "styled-components"

export const ItemList = styled.li`
    display: flex;
    margin-bottom: 2px;

    padding: 10px;

    justify-content: space-between;

    p {
        font-size: 12px;
    }

    :not(:last-child) {
        border-bottom: 1px solid #ddd;
    }
`

export const List = styled.ul`
    flex-direction: column;

    padding: 10px;

    width: 100%;

    h4 {
        padding-bottom: 10px;
    }
`
