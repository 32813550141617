import styled from "styled-components"

export const Backdrop = styled.div`
    position: fixed;
    display: flex;
    z-index: 10;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #0008;

    justify-content: center;
    align-items: center;

    div {
        display: flex;
    }

    > div {
        flex-direction: column;
    }
`

export const Panel = styled.div`
    width: 100%;
    max-width: 400px;
    min-height: 200px;
    border-radius: 15px;
    background-color: #fff;
    padding: 10px;

    text-align: center;

    align-items: center;
    justify-content: space-between;

    h3 {
        font-weight: bold;
        color: #444;
    }

    > p {
        text-align: center;
        padding-bottom: 30px;
        padding-top: 20px;
        font-size: 14px;
    }
`

export const ActionButtons = styled.div`
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
`

export const ConfirmButton = styled.button<{ color: string }>`
    width: 120px;
    height: 40px;
    aspect-ratio: 2;
    border-radius: 8px;

    > p {
        text-align: center;
    }

    flex-direction: row;

    background-color: ${(p) => p.color};

    cursor: pointer;

    color: white;
    font-weight: bold;

    transition: all 0.1s;

    :hover {
        opacity: 0.8;
        border-radius: 52px;
    }

    text-transform: uppercase;
`
