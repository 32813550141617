import styled from "styled-components"
import background from "../../../assets/images/login-background.jpg"
export const Container = styled.div`
    flex: 1;
    height: 100vh;
    background-color: #444;

    background-image: url(${background});
    background-size: cover;
    background-position: center;

    justify-content: center;
    align-items: center;
`

export const Msg = styled.h5`
    color: var(--primary);
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
    white-space: pre-wrap;
`

export const FormPanel = styled.div`
    flex-direction: column;

    background-color: #fff;
    width: 100%;
    min-width: 200px;

    max-width: 500px;

    border-radius: 10px;
    padding: 25px;

    align-items: center;
    justify-content: center;

    button {
        border-radius: 5;
    }

    > span {
        margin: 6px;
    }
`

export const ButtonsWrapper = styled.div`
    justify-content: space-between;
    width: 100%;
    align-items: center;

    button {
        cursor: pointer;
        padding: 3px;
    }
`

export const BackButton = styled.button`
    align-items: center;

    border-radius: 5px;

    transition: transform 0.05s;

    :hover {
        transform: scale(1.1);
    }

    :nth-child(2) {
        margin-left: 5px;
    }
`

export const HyperLink = styled.button`
    color: #dd1a00;
    background-color: transparent;
    border-bottom: 1px solid #dd1a00;
`

export const LoginButton = styled.button`
    height: 30px;

    background-color: ${({ disabled }) =>
        disabled ? "#e8e4db" : "var(--primary)"};
    color: #e8e4db;
    border-radius: 5px;

    color: white;

    justify-content: center;
    align-items: center;

    font-weight: bold;

    cursor: pointer;

    width: 100px;

    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    &.progress {
        width: 40px;
    }

    transition: all 0.2s;

    :hover {
        ${({ disabled }) => (disabled ? "" : "border-radius: 20px;")};
    }
`

export const Logo = styled.img`
    height: 60px;

    margin-bottom: 20px;

    justify-content: center;
`
