import React, { useContext, useState } from "react"
import { FiUser } from "react-icons/fi"
import { MdCake, MdEmail, MdOutlineFingerprint } from "react-icons/md"
import Inputfield from "src/components/inputfield"
import {
    Logo,
    Msg,
    ButtonsWrapper,
    LoginButton,
    Container,
    BackButton,
} from "../auth-page/styles"
import { ThreeDots } from "react-loader-spinner"
import AuthContext from "src/contexts/auth"
import logo from "../../../assets/svg/fh-logo.svg"
import { useNavigate } from "react-router-dom"
import { PanelContent, RegisterForm } from "./styles"
import { BiBuilding, BiChevronDown, BiChevronLeft } from "react-icons/bi"
import api from "src/rest/api"
import { TbIdBadge } from "react-icons/tb"
import moment from "moment"
import { ValidateEmail, ValidatePass } from "src/assets/functions"
import styled from "styled-components"
import InputfieldMask from "src/components/inputfield/inputfieldMask"
import InputPhone from "src/components/inputfield/InputPhone"

// import { Container } from './styles';

const Email = styled.div`
    border-radius: 50px;
    background-color: #1d1d1d;
    color: white;

    align-items: center;

    padding: 5px;

    img {
        height: 100%;
        width: 30px;
        border-radius: 50px;
        margin-right: 10px;
        aspect-ratio: 1;
    }

    margin-bottom: 10px;
`

const Register: React.FC = () => {
    const [registering, setRegistering] = useState(false)

    const context = useContext(AuthContext)

    const nav = useNavigate()

    const [form, setForm] = useState({
        name: context.GSignIn.user?.displayName ?? "",
        phone: context.GSignIn.user?.phoneNumber ?? "",
        identity: "",
        birthdate: "",
        email: context.GSignIn.user?.email ?? "",
        name_pj: "",
        password: "",
    })

    function handleForm(e: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target

        setForm({
            ...form,
            [name]: value,
        })
    }

    function handlePhone(e: string) {
        setForm({
            ...form,
            phone: e,
        })
    }

    async function handleRegister() {
        if (!ValidateEmail(form.email) || !ValidatePass(form.password)) return

        setRegistering(true)

        await api
            .post("/register/trainer", {
                ...form,
                photourl: context.GSignIn.user?.photoURL ?? null,
                birthdate: moment(form.birthdate, "DDMMYYYY").format(
                    "YYYY-MM-DD"
                ),
            })
            .then((res) => {
                setRegistering(false)

                alert(res.data)

                context.Login(form.email, form.password).then(() => {
                    nav("/")
                })
            })
            .catch((err) => {
                setRegistering(false)
                alert(err)
            })
    }

    const EnterLabel = () => {
        if (registering)
            return (
                <ThreeDots
                    height="30"
                    width="30"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    visible={true}
                />
            )
        else return <p>REGISTRAR</p>
    }

    return (
        <Container
            className="flex column"
            style={{ flex: 1, backgroundColor: "#444", height: "100vh" }}
        >
            <PanelContent>
                <RegisterForm style={{ alignItems: "center" }}>
                    <Logo src={logo} />
                    <h3>Bem vindo à Fithub!</h3>
                    <br />
                    <p>
                        A Fithub é uma plataforma desenvolvida para personal
                        trainers e consultores esportivos, criada por
                        profissionais da área.
                    </p>
                    <br />
                    <p>
                        Ao criar uma conta você poderá participar da versão de
                        teste de pré lançamento!
                    </p>
                    <br />
                </RegisterForm>
                <span style={{ margin: 10 }} />
                <RegisterForm>
                    {context.GSignIn.user && (
                        <Email>
                            <img
                                src={require("../../../assets/png/google.jpg")}
                            />
                            <div style={{ flexDirection: "column", flex: 1 }}>
                                <h4>{context.GSignIn.user?.email ?? "none"}</h4>
                                <p>Criar Conta</p>
                            </div>
                            <BiChevronDown size={24} />
                        </Email>
                    )}
                    <Inputfield
                        icon={FiUser}
                        width={`100%`}
                        inputProps={{
                            name: "name",
                            type: "username",
                            tabIndex: 1,
                            placeholder: "Nome Sobrenome",
                            onChange: handleForm,
                            value: form.name,
                        }}
                        hint="Nome"
                    />
                    <span />

                    {!context.GSignIn.user && (
                        <>
                            <Inputfield
                                icon={MdEmail}
                                width={`100%`}
                                inputProps={{
                                    name: "email",
                                    type: "email",
                                    tabIndex: 2,
                                    placeholder: "professor@email.com",
                                    onChange: handleForm,
                                    value: form.email,
                                }}
                                hint="Endereço de Email"
                            />

                            {form.email && !ValidateEmail(form.email) && (
                                <Msg>Email Inválido!</Msg>
                            )}
                            <span />
                        </>
                    )}

                    <InputPhone
                        inputProps={{
                            tabIndex: 3,
                            name: "phone",
                            value: form.phone,
                            placeholder: "(xx) xxxxx-xxxx",
                            onChange: handleForm,
                            type: "number",
                        }}
                        onChange={handlePhone}
                        width={`100%`}
                        hint="Número de Contato"
                    />
                    <span />
                    <InputfieldMask
                        inputProps={{
                            tabIndex: 4,
                            name: "identity",
                            value: form.identity,
                            placeholder: "CPF / CNPJ / ID",
                            onChange: handleForm,
                        }}
                        icon={TbIdBadge}
                        width={`100%`}
                        hint="Identificação"
                        mask={{
                            pattern: "### ### ### ###",
                            allow: ["n*"],
                        }}
                    />

                    <span />
                    <InputfieldMask
                        inputProps={{
                            tabIndex: 5,
                            name: "birthdate",
                            value: form.birthdate,
                            placeholder: "DD MM AAAA",
                            onChange: handleForm,
                        }}
                        icon={MdCake}
                        width={`100%`}
                        hint="Nascimento"
                        mask={{
                            pattern: "##/##/####",
                            allow: ["n*"],
                        }}
                    />

                    <span />
                    <Inputfield
                        icon={BiBuilding}
                        width={`100%`}
                        inputProps={{
                            name: "name_pj",

                            tabIndex: 6,
                            placeholder: form.name + " Consultoria",
                            onChange: handleForm,
                        }}
                        hint="Nome Fantasia ou Slogan"
                    />
                    <span />
                    <Inputfield
                        icon={MdOutlineFingerprint}
                        width={`100%`}
                        inputProps={{
                            name: "password",
                            type: "password",
                            tabIndex: 7,
                            placeholder: "******",
                            onChange: handleForm,
                        }}
                        hint="Senha"
                    />

                    {form.password &&
                        !ValidatePass(form.password).valido &&
                        ValidatePass(form.password).mensagem.map((e) => (
                            <Msg key={e.substring(0, 10)}>{e}</Msg>
                        ))}

                    <span />
                    <ButtonsWrapper>
                        <BackButton onClick={() => nav("/")}>
                            <BiChevronLeft />
                            <p>VOLTAR</p>
                        </BackButton>

                        <LoginButton
                            tabIndex={8}
                            disabled={
                                !ValidateEmail(form.email) ||
                                !ValidatePass(form.password).valido
                            }
                            onClick={handleRegister}
                        >
                            <EnterLabel />
                        </LoginButton>
                    </ButtonsWrapper>
                </RegisterForm>
            </PanelContent>
        </Container>
    )
}

export default Register
