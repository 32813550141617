import React from "react"
import {
    Workout,
    WorkoutIcon,
    WorkoutType,
} from "src/modules/fithub-shared/interfaces/workout"
import { Button, FloatDiv } from "./styles"
import MutableIcon from "src/components/mutable-icon"

// import { Container } from './styles';

const CreateModal: React.FC<{ create: (w: Workout) => void }> = ({
    create,
}) => {
    function Create(type: number) {
        create(new Workout(type, "Novo Treino"))
    }

    return (
        <FloatDiv className="flex">
            <div>
                <Button onClick={() => Create(WorkoutType.heating)}>
                    <MutableIcon icon={WorkoutIcon(WorkoutType.heating)} />
                    <p>Aquecimento</p>
                </Button>
                <Button onClick={() => Create(WorkoutType.aerobic)}>
                    <MutableIcon icon={WorkoutIcon(WorkoutType.aerobic)} />
                    <p>Aeróbico</p>
                </Button>
                <Button onClick={() => Create(WorkoutType.weight)}>
                    <MutableIcon icon={WorkoutIcon(WorkoutType.weight)} />
                    <p>Musculação</p>
                </Button>
                <Button onClick={() => Create(WorkoutType.crossfit)}>
                    <MutableIcon icon={WorkoutIcon(WorkoutType.crossfit)} />
                    <p>Crossfit</p>
                </Button>
            </div>
        </FloatDiv>
    )
}

export default CreateModal
