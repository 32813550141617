import React, { useEffect, useState } from "react"
import { MdEmail, MdLock } from "react-icons/md"
import Inputfield from "src/components/inputfield"
import logo from "../../../assets/svg/fh-logo.svg"
import {
    FormPanel,
    Logo,
    LoginButton,
    Msg,
    ButtonsWrapper,
    Container,
} from "../auth-page/styles"
import { useLocation, useNavigate } from "react-router-dom"
import api from "src/rest/api"
import mostrarAlerta from "src/components/utils/alert"
import EmailRecoveryInput from "src/modules/fithub-shared/components/EmailRecoveryInput"
import useCountdownTimer from "src/components/hooks/CountdownTimer"

// import { Container } from './styles';

export interface RecoverState {
    user: string
}

const RecoverPass: React.FC = () => {
    const { user } = useLocation().state as RecoverState
    const [email, setEmail] = useState(user)
    const nav = useNavigate()
    const [code, setCode] = useState("")
    const [recover, setRecover] = useState(false)

    const [err, setErr] = useState("")

    const { countdown, startCountdown, intervalActive } = useCountdownTimer(30)

    const [pass, setPass] = useState("")
    const [confirm, setConfirm] = useState("")

    useEffect(() => {
        RequestCode()
    }, [])

    function SetCode(code: string) {
        setCode(code)

        if (code.length === 6)
            api.post<string>("recover/verify", { user, code })
                .then((res) => {
                    if (res.status === 201) setRecover(true)
                    else setErr(res.data)
                })
                .catch((err) => setErr(err))
    }

    function SetErr(nerr: string) {
        setErr("")
        setTimeout(() => {
            setErr(nerr)
        }, 200)
    }

    function RequestCode() {
        if (!intervalActive) {
            api.post("recover/password", { user: email, code })
                .then((res) => {
                    if (res.data === "success") {
                        SetErr("Email enviado para " + email)
                        startCountdown()
                    } else {
                        SetErr(res.data)
                    }
                })
                .catch((err) => alert(err))
        } else {
            mostrarAlerta(`Aguarde ${countdown} segundo(s)\n` + user)
        }
    }

    function NewPass() {
        api.post("recover/newpass", { user: email, pass }).then((res) => {
            if (res.status === 200) {
                alert(
                    "Senha alterada com Sucesso! Redirecionando para o Login..."
                )
                nav("/")
            }
        })
    }

    return (
        <Container
            className="flex column"
            style={{ flex: 1, backgroundColor: "#444" }}
        >
            <FormPanel>
                <Logo src={logo} />
                <div
                    style={{
                        width: "100%",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "var(--panel)",
                        padding: 5,
                        borderRadius: 8,
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Inputfield
                            inputProps={{
                                defaultValue: email.toLocaleLowerCase(),
                            }}
                            onChangeText={setEmail}
                            icon={MdEmail}
                            style={{ flex: 1, marginRight: 10 }}
                        />

                        {!recover && (
                            <LoginButton
                                disabled={intervalActive}
                                onClick={RequestCode}
                                style={{
                                    width: "unset",
                                    paddingInline: 10,
                                    backgroundColor: `white`,
                                }}
                            >
                                <p style={{ color: "var(--primary)" }}>
                                    {countdown > 0
                                        ? `Aguarde... ${countdown}`
                                        : `Re-enviar Código`}
                                </p>
                            </LoginButton>
                        )}
                    </div>
                    {err && <p style={{ padding: 10, fontSize: 12 }}>{err}</p>}
                </div>

                <span />
                {recover ? (
                    <div style={{ width: "100%" }}>
                        <Inputfield
                            onChangeText={setPass}
                            icon={MdLock}
                            width={`100%`}
                            inputProps={{
                                id: "npass",
                                type: "password",
                                placeholder: "Nova senha",
                                value: pass,
                            }}
                        />
                        <span style={{ margin: 5 }} />
                        <Inputfield
                            onChangeText={setConfirm}
                            icon={MdLock}
                            width={`100%`}
                            inputProps={{
                                id: "npassconf",
                                type: "password",
                                placeholder: "Confirmar nova senha",
                                value: "confirm",
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <span />
                        <EmailRecoveryInput onChange={SetCode} />
                    </>
                )}

                <Msg>{/*error*/}</Msg>
                <span />
                <ButtonsWrapper>
                    <button onClick={() => nav("/")}>VOLTAR</button>
                    {recover && (
                        <LoginButton onClick={NewPass}>
                            <p>CONFIRMAR</p>
                        </LoginButton>
                    )}
                </ButtonsWrapper>
            </FormPanel>
        </Container>
    )
}

export default RecoverPass
