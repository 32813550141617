import React, { useEffect, useState } from "react"
import { DataWrapper } from "../../styles"
import HistoryWrapper from "../HistoryWrapper"
import { ExerciseData } from "../.."
import api from "src/rest/api"
import { Audio } from "react-loader-spinner"
import { IoChatboxEllipses } from "react-icons/io5"
import styled from "styled-components"
import { BiX } from "react-icons/bi"
import { CenteredDiv } from "src/screens/calendar/styling/styles"
import { useQuery } from "react-query"

// import { Container } from './styles';

interface Exercise {
    exercise_name: string
    exercise_data: ExerciseData[]
    id: string
}

const ExcFilter = styled.div`
    color: #777;

    padding-bottom: 10px;
    p {
        border: 1px solid #777;
        padding: 7px;

        border-radius: 25px;

        align-items: center;

        cursor: pointer;
    }

    p:active {
        opacity: 0.8;
    }
`

const ProgressionView: React.FC<{ planId: string; highlight?: string }> = ({
    planId,
    highlight,
}) => {
    const [exercises, setExercises] = useState<Exercise[]>([])
    const [filter, setFilter] = useState(highlight)

    const { data, refetch, isLoading } = useQuery(["PVIEW_", planId], () =>
        api
            .post<Exercise[]>("/charge/history/get", {
                plan: planId,
            })
            .then((res) => res.data)
            .catch((err) => {
                alert(err)
                return [] as Exercise[]
            })
    )

    function filterResults() {
        if (!data) return
        setExercises(filter ? data.filter((e) => e.id === filter) : data)
    }
    const removeFilter = () => setFilter(undefined)

    useEffect(() => {
        filterResults()
    }, [data, filter])

    if (isLoading || !data) {
        return isLoading ? (
            <CenteredDiv>
                <Audio color="black" height={40} width={40} />
            </CenteredDiv>
        ) : (
            <p>Erro!</p>
        )
    } else {
        return (
            <>
                {filter && (
                    <ExcFilter>
                        <p onClick={removeFilter}>
                            <BiX />
                            &#34;
                            {
                                data.find((e) => e.id === highlight)
                                    ?.exercise_name
                            }
                            &#34;
                        </p>
                    </ExcFilter>
                )}
                {exercises.map((e, i) => (
                    <DataWrapper key={i + "_prog"}>
                        <div
                            id={e.id === highlight ? "highlight" : ""}
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <h3
                                style={
                                    e.id === highlight
                                        ? { fontWeight: "bold" }
                                        : {}
                                }
                            >
                                {e.exercise_name}
                            </h3>
                            {e.id === highlight && (
                                <IoChatboxEllipses
                                    className="pulse"
                                    style={{ marginLeft: 10 }}
                                />
                            )}
                        </div>
                        <HistoryWrapper
                            data={e.exercise_data}
                            highlited={e.id === highlight}
                        />
                    </DataWrapper>
                ))}
            </>
        )
    }
}

export default ProgressionView
