import Cookies from "js-cookie"

export class Storage {
    static remove(TAG: string) {
        Cookies.remove(TAG)
    }

    static get<T>(tag: string): T | undefined {
        const item = Cookies.get(tag)
        if (!item) {
            return undefined
        }
        try {
            return JSON.parse(item) as T
        } catch (error) {
            console.error("Error parsing JSON from localStorage:", error)
            return undefined
        }
    }

    static set<T>(tag: string, content: T) {
        Cookies.set(tag, JSON.stringify(content), { expires: 30 })
        return content
    }

    static renew<T>(tag: string): T | undefined {
        const data = this.get<object>(tag)
        if (data) this.set(tag, JSON.stringify(data))
        return data as T | undefined
    }
}
