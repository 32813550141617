import React, { useState } from "react"
import SideListView from "src/components/SideListView"
import { ListingDiv, List, ExcItem } from "./styles"
import ListInfoHeader from "src/modules/protocols/components/StudentInfoHeader"
import { ExercisesIcon } from "src/assets/static/icons"
import { Queries } from "src/assets/queries/exerciseTemplates/queries"
import EmptyListInfo from "src/modules/protocols/components/EmptyListInfo"
import { GetParameter } from "src/assets/functions"
import { useNavigate } from "react-router-dom"
import { BiChevronRight, BiSearch, BiUser } from "react-icons/bi"
import { CgTemplate, CgYoutube } from "react-icons/cg"
import Inputfield from "src/components/inputfield"
import { FilterAndSortExc } from "../exercise"
import ExerciseDetails from "./ExerciseDetails"
import { useNewExerciseMutation } from "src/assets/queries/exerciseTemplates/mutations"
import { FaDashcube, FaYoutubeSquare } from "react-icons/fa"
import { PiUserCircleBold, PiYoutubeLogoFill } from "react-icons/pi"
import { MdDashboard, MdDashboardCustomize } from "react-icons/md"
import { RiDashboard2Fill, RiDashboard2Line } from "react-icons/ri"

// import { Container } from './styles';

const sliceSize = 30

const NewExercises: React.FC = () => {
    const [slice, setSlice] = useState(sliceSize)

    const [search, setSearch] = useState("")
    const { data, isLoading, refetch } = Queries.useExercisesList()

    const newExerciseMutation = useNewExerciseMutation()

    const selected = GetParameter("id")

    const nav = useNavigate()

    const handleSetSearch = (s: string) => {
        setSearch(s)
        setSlice(sliceSize)
    }

    const threshold = 50 // Pixels antes do final da lista para carregar mais itens

    const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        const isNearBottom =
            scrollHeight - scrollTop - clientHeight <= threshold

        if (isNearBottom) {
            setSlice((prevSlice) => prevSlice + sliceSize)
        }
    }

    function Select(e: React.PointerEvent<HTMLLIElement>) {
        const id = e.currentTarget.getAttribute("data-id")

        nav(selected === id ? "/tools/exercises" : `/tools/exercises?id=${id}`)
    }

    const handleNewExercise = async () => {
        setSearch("")
        await newExerciseMutation.mutateAsync(
            "Novo Template - " +
                data
                    ?.filter((e: any) => e.name.startsWith("Novo Template"))
                    .reduce((a: number) => {
                        return a + 1
                    }, 0)
        )
    }

    return (
        <SideListView
            list={
                <ListingDiv>
                    <ListInfoHeader
                        create={handleNewExercise}
                        subtitle="Exercícios"
                        data={{
                            title: "Templates",
                            icon: ExercisesIcon,
                        }}
                    >
                        <div
                            style={{
                                padding: 10,
                                width: "100%",
                            }}
                        >
                            <Inputfield
                                icon={BiSearch}
                                width={"100%"}
                                radius="50px"
                                onChangeText={handleSetSearch}
                                inputProps={{
                                    placeholder: "Procurar Exercício...",
                                }}
                            />
                        </div>
                    </ListInfoHeader>
                    <List onScroll={handleScroll}>
                        {isLoading || !data ? (
                            <EmptyListInfo
                                Icon={ExercisesIcon}
                                type={"Exercícios Template"}
                                hyperlink={{
                                    text: "Saiba mais:",
                                }}
                            />
                        ) : (
                            FilterAndSortExc(data, search)
                                .slice(0, slice)
                                .map((e, i) => (
                                    <ExcItem
                                        key={`exc_${i}`}
                                        data-id={e.id}
                                        selected={selected === e.id}
                                        onClick={Select}
                                        draggable
                                        onDragStart={(event) => {
                                            event.dataTransfer.setData(
                                                "exercise",
                                                JSON.stringify(e)
                                            )
                                        }}
                                    >
                                        <h4>{e.name}</h4>
                                        <div>
                                            <div style={{ paddingRight: 15 }}>
                                                <PiYoutubeLogoFill
                                                    color={
                                                        e.hasmedia
                                                            ? "#444"
                                                            : "#ccc"
                                                    }
                                                    size={24}
                                                />
                                            </div>
                                            {e.editable ? (
                                                <PiUserCircleBold size={18} />
                                            ) : (
                                                <MdDashboard size={18} />
                                            )}
                                        </div>
                                    </ExcItem>
                                ))
                        )}
                    </List>
                </ListingDiv>
            }
            content={
                <div style={{ flex: 1, flexDirection: "column" }}>
                    <ExerciseDetails id={selected} reloadList={refetch} />
                </div>
            }
            view={"CONTENT"}
        />
    )
}

export default NewExercises
