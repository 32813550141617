import React, { useState } from "react"
import ActivityItem from "../ActivityItem"
import { TbActivityHeartbeat } from "react-icons/tb"
import { Activity } from "src/modules/fithub-shared/notifications/index.types"
import api from "src/rest/api"
import styled from "styled-components"
import { MdDeleteForever } from "react-icons/md"
import { useQuery } from "react-query"
import { useStudent } from "src/contexts/student"
import { LineWave } from "react-loader-spinner"

// import { Container } from './styles';

const RemoveBtn = styled.button`
    background-color: #777;
    cursor: pointer;
    color: white;
    font-weight: bold;
    padding: 3px;
    border-radius: 25px;

    font-size: 12px;

    margin-right: 10px;

    :active {
        opacity: 0.7;
    }
`

const Activities: React.FC = () => {
    const { student } = useStudent()

    const [activities, setActivities] = useState<Activity[]>()

    const query = useQuery(["activities", student?.id], () =>
        api
            .post<Activity[]>("/activities/list", { ref: student?.id })
            .then((res) => {
                setActivities(res.data)
            })
    )

    const [remove, setRemove] = useState<string[]>([])

    function RemoveSelected() {
        api.post("/notification/dismiss", {
            ids: `(${remove.map((id) => `'${id}'`).join(",")})`,
        }).then(() => {
            setRemove([])
            setActivities(undefined)
            query.refetch()
        })
    }

    function toggle(id: string) {
        if (remove.includes(id)) {
            // Remove o item sem modificar o array original
            setRemove(remove.filter((item) => item !== id))
        } else {
            // Adiciona o item ao array
            setRemove([...remove, id])
        }
    }

    return (
        <ul style={{ position: "relative" }}>
            {remove.length ? (
                <div
                    style={{
                        width: "100%",
                        flexDirection: "row",
                        paddingInline: 12,
                        alignItems: "center",
                    }}
                >
                    <RemoveBtn onClick={RemoveSelected}>
                        <MdDeleteForever size={18} />
                    </RemoveBtn>
                    <p style={{ paddingBottom: 1, fontWeight: "bold" }}>
                        EXCLUIR
                    </p>
                </div>
            ) : (
                <></>
            )}

            {activities && activities.length > 0 ? (
                <>
                    {activities.map((e, i) => (
                        <ActivityItem
                            key={`${i}_Act`}
                            activity={e}
                            selected={remove.includes(e.id)}
                            select={toggle}
                        />
                    ))}
                </>
            ) : (
                <div
                    style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            flexDirection: "column",
                            alignItems: "center",
                            color: "#888",
                        }}
                    >
                        {query.isLoading ? (
                            <LineWave color="black" />
                        ) : (
                            <>
                                <TbActivityHeartbeat size={32} />
                                <p>Não há atividades notificadas!</p>
                            </>
                        )}
                    </div>
                </div>
            )}
        </ul>
    )
}

export default Activities
