import React from "react"
import { FiAlertTriangle } from "react-icons/fi"
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px solid #ffcc00; /* Amarelo mais escuro para contraste */
    color: #333; /* Cor de texto mais escura para melhor legibilidade */
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin-block: 20px;
    border-radius: 10px;
    background-color: #ffcc0022; /* Fundo branco semi-transparente */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra suave para destaque */
`

const WorkingWarn: React.FC<{ subtitle?: string; fun?: boolean }> = ({
    subtitle,
    fun,
}) => {
    return (
        <Container className="flex">
            <FiAlertTriangle />
            <span style={{ margin: 5 }} />
            <h5>
                Estamos trabalhando nesta {fun ? "função" : "página"} no
                momento!
            </h5>
            {subtitle ? (
                <h5>{subtitle}</h5>
            ) : (
                <h5>Fique atento à alterações no grupo de testadores.</h5>
            )}
        </Container>
    )
}

export default WorkingWarn
