import React, { useContext } from "react"

import { AuthProvider } from "./contexts/auth"
import AuthRoutes from "./routes/AuthRoutes"
import { ExpiredPage, LogoutRoute, SignedRoutes } from "./routes/SignedRoutes"
import TokenInspector from "./components/TokenInspector"
import GlobalStyles, { StyledRoutes } from "./assets/GlobalStyles"
import { Route } from "react-router-dom"
import PlansOver from "./modules/TrainerCheckout"
import { OverlayProvider } from "./screens/checkout/context"

function App() {
    return (
        <div className="App">
            <OverlayProvider>
                <TokenInspector />
                <AuthProvider
                    signedComponent={<SignedRoutes />}
                    unsignedComponent={<AuthRoutes />}
                />

                <GlobalStyles />
            </OverlayProvider>
        </div>
    )
}

export default App
