import React, { useEffect, useState } from "react"
import { LineWave } from "react-loader-spinner"
import api from "src/rest/api"
import { ExercisesIcon, ExercisesIconEdit } from "src/assets/static/icons"
import { ExerciseTemplate } from "src/modules/fithub-shared/interfaces/templates"
import {
    EditableLabel,
    ExerciseInfo,
    Footer,
    NameHeader,
    Section,
} from "./styles"
import convertYouTubeLinkToEmbed from "src/modules/fithub-shared/functions/youtube-utils"
import { useDialog } from "src/contexts/dialog-context"
import { useDeleteExerciseMutation } from "src/assets/queries/exerciseTemplates/mutations"
import { useNavigate } from "react-router-dom"
import SelectButtons from "src/modules/templates/components/SelectButtons"
import MuscleSerie from "src/modules/templates/components/MuscleSerie"
import ExercisesReplacement from "src/modules/templates/components/ExercisesReplacement"
import { useQuery } from "react-query"
import styled from "styled-components"
import { CenteredDiv } from "src/screens/calendar/styling/styles"
import WorkingWarn from "src/components/WorkingWarn"

export const Div = styled.div`
    display: flex;
    align-items: center;

    flex: 1;

    background-color: cyan;

    > div {
        flex-direction: column;
        align-items: center;
    }
`

const ExerciseDetails: React.FC<{
    id: string | undefined
    reloadList: () => void
}> = ({ id, reloadList }) => {
    const [exercise, setExercise] = useState<ExerciseTemplate>()
    const [modified, setModified] = useState(false)

    useEffect(() => {
        setExercise(undefined)
    }, [id])

    function onChangeData(e: React.ChangeEvent<HTMLInputElement>) {
        if (!exercise) return

        setModified(true)

        const { name, value } = e.target

        setExercise({
            ...exercise,
            [name]: value,
        })
    }

    function onChangeMode(v: string) {
        if (!exercise) return
        setExercise({
            ...exercise,
            mode: v,
        })
        setModified(true)
    }

    function onChangeJoint(v: string) {
        if (!exercise) return
        setExercise({
            ...exercise,
            joint: v,
        })
        setModified(true)
    }

    function onChangeProfile(v: string) {
        if (!exercise) return
        setExercise({
            ...exercise,
            profile: v,
        })

        setModified(true)
    }

    function onBlurMedia(e: React.FocusEvent<HTMLInputElement>) {
        const { name, value } = e.target

        if (!exercise || exercise.media_url === value) return

        setModified(true)

        setExercise({
            ...exercise,
            [name]: convertYouTubeLinkToEmbed(value),
        })
    }

    const { isLoading, data } = useQuery(["exc", id], () =>
        api
            .post<ExerciseTemplate>("/exercises/get", {
                id,
            })
            .then((res) => {
                if (!modified || exercise?.id != res.data.id) {
                    setTimeout(() => {
                        setExercise(res.data)
                    }, 100)
                    setModified(false)
                }
                return res.data
            })
    )

    function ApplyEdit() {
        if (!exercise) return

        api.post<ExerciseTemplate>("/exercises/edit", {
            ...exercise,
        })
            .then((res) => {
                setExercise({ ...res.data, editable: true })
                setModified(false)
                reloadList()
            })
            .catch((err) => alert(err))
    }

    const dialog = useDialog()

    const exerciseDelete = useDeleteExerciseMutation()

    const nav = useNavigate()

    function Delete() {
        dialog.openDialog(
            "Excluir Exercício",
            `Atenção!
            excluir um exercício pode invalidar diversos outros dados da prescrição.
            \nTem certeza que deseja excluir o exercício "${exercise?.name}" de sua lista?`,
            () => {
                exerciseDelete.mutateAsync(exercise?.id)
                nav("/tools/exercises")
            }
        )
    }

    if (!id)
        return (
            <CenteredDiv>
                <div>
                    <ExercisesIcon size={48} />
                    <span style={{ margin: 5 }} />
                    <p>Selecione um Exercício!</p>
                </div>
            </CenteredDiv>
        )
    else if (isLoading || !exercise) {
        return (
            <CenteredDiv>
                <LineWave width={60} height={60} color="black" />
            </CenteredDiv>
        )
    } else {
        return (
            <CenteredDiv style={{ flexDirection: "column", paddingBlock: 45 }}>
                <ExerciseInfo className="flex">
                    <NameHeader
                        style={{
                            flexDirection: "column",
                            paddingBottom: 20,
                        }}
                    >
                        <h4 style={{ fontSize: 24 }}>Exercício Template</h4>
                        <p style={{ fontSize: 14 }}>
                            Editar ou remover Exercício predefinido.
                        </p>
                    </NameHeader>
                    <div
                        style={{
                            width: "100%",
                            paddingBottom: 20,
                            alignItems: "start",
                        }}
                    >
                        <div
                            style={{
                                flexDirection: "column",
                                flex: 1,
                                height: "100%",
                            }}
                        >
                            <NameHeader>
                                <ExercisesIconEdit />
                                <EditableLabel
                                    name={"name"}
                                    disabled={!exercise.editable}
                                    value={exercise.name}
                                    onChange={onChangeData}
                                    style={{ fontSize: 24 }}
                                />
                            </NameHeader>
                            <div
                                style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    flex: 1,
                                }}
                            >
                                <div style={{ flexDirection: "column" }}>
                                    <SelectButtons
                                        title={"Tipo"}
                                        buttons={[
                                            {
                                                title: "LIVRE",
                                            },
                                            {
                                                title: "MAQUINA",
                                            },
                                        ]}
                                        buttonWidth={80}
                                        onSelect={onChangeMode}
                                        defaultValue={exercise.mode}
                                        disabled={!exercise.editable}
                                    />

                                    <span style={{ marginInline: 10 }} />

                                    <SelectButtons
                                        title={"Articulação"}
                                        buttons={[
                                            {
                                                title: "MONO",
                                            },
                                            {
                                                title: "MULTI",
                                            },
                                        ]}
                                        buttonWidth={80}
                                        onSelect={onChangeJoint}
                                        defaultValue={exercise.joint}
                                        disabled={!exercise.editable}
                                    />
                                </div>
                                <SelectButtons
                                    title={"Perfil de Resistência"}
                                    buttons={[
                                        {
                                            title: "ASCENDENTE",
                                            value: "ASC",
                                        },

                                        {
                                            title: "DESCENDENTE",
                                            value: "DESC",
                                        },
                                        {
                                            title: "U INVERTIDO",
                                            value: "INV",
                                        },
                                    ]}
                                    onSelect={onChangeProfile}
                                    defaultValue={exercise.profile}
                                    disabled={!exercise.editable}
                                />

                                {exercise.media_url.includes("youtube") && (
                                    <iframe
                                        className="shadow"
                                        width={300}
                                        style={{
                                            aspectRatio: "16 / 9",
                                            borderRadius: 15,
                                            border: "1px solid black",
                                            backgroundColor: "black",
                                        }}
                                        src={`${convertYouTubeLinkToEmbed(
                                            exercise.media_url
                                        )}?modestbranding=1&rel=0&showinfo=0&controls=1`}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            flexDirection: "column",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <Section disabled={!exercise.editable}>
                            <p>URL de Mídia:</p>
                            <EditableLabel
                                name={"media_url"}
                                disabled={!exercise.editable}
                                value={exercise.media_url}
                                onChange={onChangeData}
                                onBlur={onBlurMedia}
                            />
                        </Section>
                    </div>

                    <Footer>
                        {modified && (
                            <button onClick={ApplyEdit}>
                                Salvar Modificações
                            </button>
                        )}

                        {exercise.editable && (
                            <button style={{ marginLeft: 10 }} onClick={Delete}>
                                Excluir
                            </button>
                        )}
                    </Footer>
                </ExerciseInfo>

                <ExerciseInfo className="flex">
                    <NameHeader
                        style={{
                            flexDirection: "column",
                            paddingBottom: 20,
                        }}
                    >
                        <h4 style={{ fontSize: 24 }}>Volume de Treino</h4>
                        <p style={{ fontSize: 14 }}>
                            Listagem de cálculo de volume, contabilizado X
                            séries para músculo Y.
                        </p>
                    </NameHeader>
                    {/*<div style={{ width: "100%" }}><MuscleSerie /></div>*/}
                    <WorkingWarn fun />
                </ExerciseInfo>

                <ExerciseInfo className="flex">
                    <NameHeader
                        style={{
                            flexDirection: "column",
                            paddingBottom: 20,
                        }}
                    >
                        <h4 style={{ fontSize: 24 }}>
                            Exercícios de Substituição
                        </h4>
                        <p style={{ fontSize: 14 }}>
                            Listagem de exercícios alternativos, caso o aluno
                            não consiga executar o primário.
                        </p>
                    </NameHeader>
                    {/*<ExercisesReplacement id={id} />*/}
                    <WorkingWarn fun />
                </ExerciseInfo>
            </CenteredDiv>
        )
    }
}

export default ExerciseDetails
