import api from "src/rest/api"
import { useQuery } from "react-query"
import { ExTemplateLI } from "src/modules/fithub-shared/interfaces/templates"

export const useExercisesList = () => {
    return useQuery("exercises", () =>
        api
            .post("/exercises/list")
            .then((res) => res.data as ExTemplateLI[])
            .catch((error) => {
                console.error("Erro na requisição:", error)
                return [] as ExTemplateLI[]
            })
    )
}

export * as Queries from "./queries"
