import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 10px;

    align-items: center;

    > div > button {
        align-items: center;
        padding-inline: 10px;
        background-color: var(--primary);
        border-radius: 5px;
        color: white;

        margin-left: 10px;

        cursor: pointer;
    }
`
