import React from "react"
import { Route } from "react-router-dom"
import { StyledRoutes } from "src/assets/GlobalStyles"
import RecoverPass from "src/modules/login/RecoverPass"
import LoginPage from "src/modules/login/auth-page"
import Register from "src/modules/login/register"
import { ExpiredPage } from "./SignedRoutes"

// import { Container } from './styles';

const AuthRoutes: React.FC = () => {
    return (
        <StyledRoutes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/recover" element={<RecoverPass />} />
            <Route path="/expired" element={<ExpiredPage />} />
        </StyledRoutes>
    )
}

export default AuthRoutes
