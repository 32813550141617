import moment from "moment"
import React, { useContext } from "react"
import { HiDocument, HiDocumentAdd, HiDocumentDuplicate } from "react-icons/hi"
import { CreateButton, List, ListArea, ListItem } from "./styles"
import {
    useCreateAssessment,
    useDuplicateAssessment,
} from "src/modules/assessment/caching/queries"
import ListInfoHeader from "src/modules/protocols/components/StudentInfoHeader"
import { useStudent } from "src/contexts/student"
import { useNavigate } from "react-router-dom"
import { useCustomContextMenu } from "src/components/UseContextMenu"
import { BiCopy, BiTrashAlt } from "react-icons/bi"
import { AssessmentClass } from "src/modules/fithub-shared/anthropometry/assessment"
import { useAssessmentContext } from "../context/assessmentContext"
import { useDialog } from "src/contexts/dialog-context"

// import { Container } from './styles';

interface ListProps {
    selected: string
    onSetSelected(id: string): void
    handleDelete(): void
    Reload(): void
    list: AssessmentClass[] | undefined
}

const AssessmentList: React.FC<ListProps> = (props) => {
    const student = useStudent().student

    const assessmentC = useAssessmentContext()

    const create = useCreateAssessment(student?.id)

    const duplicate = useDuplicateAssessment(props.selected, student?.id)

    const { showContextMenu, closeContextMenu, ContextMenu } =
        useCustomContextMenu()

    function Delete() {
        props.handleDelete()
        closeContextMenu()
    }

    function Duplicate() {
        duplicate.mutateAsync().then(() => {
            closeContextMenu()
            props.Reload()
        })
    }

    const contextItems = [
        {
            title: "Duplicar",
            icon: BiCopy,
            action: Duplicate,
        },
        { title: "Deletar", icon: BiTrashAlt, action: Delete },
    ]

    async function CreateAssessment() {
        create.mutateAsync()
        props.Reload
    }

    const GetDateDescription = (date: Date) => {
        //alert(date)
        return moment(date).format("dddd, D [de] MMMM, YYYY - HH:mm")
    }

    const dialog = useDialog()

    function showSaveDialog() {
        dialog.openDialog(
            "Aviso",
            "A avaliação atual contém modificações não salvas! Gostaria de salvar antes de sair?",
            assessmentC.save,
            "Salvar"
        )
    }

    function onSelect(id: string) {
        if (!assessmentC.saved && props.selected) {
            alert("Salve a avaliação atual antes de acessar uma diferente!")
            return
        }

        props.Reload()
        if (props.selected !== id) props.onSetSelected(id)
    }

    const navigate = useNavigate()

    function Compare() {
        if (!assessmentC.saved && props.selected) {
            alert("Salve a avaliação atual antes de acessar uma diferente!")
            return
        }

        props.Reload()
        navigate("compare")
    }

    return (
        <ListArea>
            <ContextMenu />
            <ListInfoHeader data={undefined} subtitle="AVALIAÇÕES">
                <div style={{ width: "100%" }}>
                    <CreateButton onClick={CreateAssessment}>
                        <HiDocumentAdd color="#444" />
                        <p>Criar Avaliação</p>
                    </CreateButton>
                    <span style={{ margin: 5 }} />
                    <CreateButton onClick={Compare}>
                        <HiDocumentDuplicate color="#444" />
                        <p>Comparar Avaliações</p>
                    </CreateButton>
                </div>
            </ListInfoHeader>
            <span style={{ margin: 5 }} />
            <List>
                {props.list &&
                    props.list.map((element, index) => (
                        <ListItem
                            key={index}
                            onContextMenu={(e) =>
                                showContextMenu(e, contextItems)
                            }
                            selected={element.id === props.selected}
                            onClick={() => onSelect(element.id)}
                            onAuxClick={() => onSelect(element.id)}
                        >
                            <HiDocument size={22} color="#444" />
                            <div>
                                <h4>{element.title}</h4>
                                <p>{GetDateDescription(element.created_at)}</p>
                            </div>
                        </ListItem>
                    ))}
            </List>
        </ListArea>
    )
}

export default AssessmentList
