import styled from "styled-components"

export const ListArea = styled.div`
    padding: 10px;
    height: 100%;
    flex-direction: column;
    overflow-y: hidden;
    width: 100%;
`

export const List = styled.ul`
    flex-direction: column;
    overflow-y: scroll;
`

export const CreateButton = styled.button`
    background-color: #ddd;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border-radius: 10px;

    width: 100%;
    height: 50px;

    p {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        color: #444;
    }

    cursor: pointer;

    :hover {
    }
`

export const ListItem = styled.button<{ selected: boolean }>`
    flex-shrink: 0;

    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;

    border: 1px solid ${(p) => (p.selected ? "#000" : "#ccc")};

    background-color: ${(p) => (p.selected ? "#ccc" : "#fff")};

    overflow-y: scroll;
    padding: 10px;

    height: 55px;
    align-items: center;

    border-radius: 8px;

    margin-bottom: 5px;

    :hover {
        opacity: 0.8;
    }

    :active {
        opacity: ${(p) => (p.selected ? "0.8" : "0.65")};
    }

    cursor: pointer;

    > div {
        flex-direction: column;
        margin-left: 10px;

        h4 {
            font-size: 14px;
            font-weight: normal;
            margin-bottom: 2.5px;
        }

        p {
            font-size: 11px;
            text-transform: uppercase;
            opacity: 0.5;
        }
    }
`
