import React, { useState } from "react"
import PhoneInput, { CountryData, PhoneInputProps } from "react-phone-input-2"
import "./style-alt.css"
import { IconType } from "react-icons"
import styled from "styled-components"
import { Area } from "../styles"

export const VerticalWrapper = styled.div<{ width?: number | string }>`
    flex: 1;
    width: ${(p) => p.width && p.width};
    height: 45px;
    flex-shrink: 0;
    flex-direction: column;
`

interface PhoneInputMaskProps extends PhoneInputProps {
    error?: boolean
    icon?: IconType
    width?: number | string
    radius?: string
    backgroundColor?: string
    hint?: string
    onChange(value: string): void
}

const StyledPhoneInput = styled(PhoneInput)`
    * {
        display: block;
        box-shadow: none;
        font-family: "Exo 2";
    }
`

const InputPhone: React.FC<PhoneInputMaskProps> = (props) => {
    const [value, setValue] = useState({
        number: "",
        dial: "55",
    })
    function onChangePhone(value: string, data: CountryData) {
        setValue({
            number: value,
            dial: data.dialCode,
        })
    }

    function onBlur(e: React.FocusEvent<HTMLInputElement>) {
        props.onChange(e.target.value)
    }

    return (
        <VerticalWrapper width={props.width}>
            {props.hint && (
                <p
                    style={{
                        width: "100%",
                        fontSize: 12,
                        paddingBottom: 5,
                    }}
                >
                    {props.hint}
                </p>
            )}
            <StyledPhoneInput
                {...props}
                country="br" // Define o país inicial
                enableSearch
                onChange={onChangePhone}
                onBlur={onBlur}
                value={value.number}
                inputProps={{
                    name: "phone",
                    required: true,
                    onBlur: onBlur,
                }}
            />
        </VerticalWrapper>
    )
}

export default InputPhone
