import React, { createContext, useContext, useState, ReactNode } from "react"

// Definindo o tipo dos dados que serão armazenados no contexto
interface OverlayType {
    show: boolean
    dismiss: () => void
    setShow: (value: ReactNode) => void
}

// Criando o contexto com um valor padrão
const Overlay = createContext<OverlayType | undefined>(undefined)

// Criando o provider do contexto
const OverlayProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [show, setShow] = useState<ReactNode>()

    function dismiss() {
        setShow(undefined)
    }

    return (
        <Overlay.Provider
            value={{ show: show !== undefined, setShow, dismiss }}
        >
            <div style={{ position: "relative" }}>
                {/* Renderiza o children com blur se show não for undefined */}
                <div style={{ filter: show ? "blur(4px)" : "none" }}>
                    {children}
                </div>

                {/* Renderiza o overlay quando show não é undefined */}
                {show && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.5)", // Fundo semi-transparente
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1000,
                        }}
                    >
                        {show}
                    </div>
                )}
            </div>
        </Overlay.Provider>
    )
}

// Hook para usar o contexto mais facilmente
const useOverlay = () => {
    const context = useContext(Overlay)
    if (context === undefined) {
        throw new Error("useOverlay must be used within an OverlayProvider")
    }
    return context
}

export { OverlayProvider, useOverlay }
