import React, { useContext, useEffect, useState } from "react"
import { MdEmail, MdOutlineFingerprint } from "react-icons/md"
import Inputfield from "src/components/inputfield"
import {
    FormPanel,
    Logo,
    Msg,
    ButtonsWrapper,
    LoginButton,
    HyperLink,
} from "../auth-page/styles"
import logo from "../../../assets/svg/fh-logo.svg"
import { ThreeDots } from "react-loader-spinner"
import AuthContext from "src/contexts/auth"
import { useNavigate } from "react-router-dom"
import GoogleButton from "../GoogleButton"
import Divider from "../Divider"
// import { Container } from './styles';

const Login: React.FC<{ googleAuth: () => void }> = (props) => {
    const [logging, setLogging] = useState(false)

    const [error, setError] = useState("")

    const [user, setUser] = useState("")
    const [password, setPassword] = useState("")

    const context = useContext(AuthContext)
    const nav = useNavigate()

    function Login() {
        if (logging) return

        setLogging(true)
        context
            .Login(user.toLowerCase(), password)
            .then((res) => {
                setTimeout(() => {
                    setLogging(false)
                }, 1000)
                setError(res)
            })
            .catch((err) => {
                setLogging(false)
                setError(err)
            })
    }

    useEffect(() => {
        function handleKeyPress(event: KeyboardEvent) {
            if (event.key === "Enter") {
                event.preventDefault()
                Login()
            }
        }

        document.addEventListener("keydown", handleKeyPress)

        return () => {
            document.removeEventListener("keydown", handleKeyPress)
        }
    }, [user, password])

    const EnterLabel = () => {
        if (logging)
            return (
                <ThreeDots
                    height="30"
                    width="30"
                    color="#713217"
                    ariaLabel="three-dots-loading"
                    visible={true}
                />
            )
        else return <p style={{ paddingInline: 10 }}>ENTRAR</p>
    }

    function Register() {
        try {
            context.GSignIn.setUser(null)
        } finally {
            setTimeout(() => {
                nav("/register")
            }, 100)
        }
    }

    return (
        <FormPanel>
            <Logo src={logo} />

            <Inputfield
                inputProps={{
                    type: "email",
                    placeholder: "E-mail",
                }}
                onChangeText={setUser}
                icon={MdEmail}
                width={`100%`}
            />
            <span />
            <Inputfield
                inputProps={{
                    type: "password",
                    placeholder: "Senha",
                }}
                onChangeText={setPassword}
                icon={MdOutlineFingerprint}
                width={`100%`}
            />
            <Msg>{error}</Msg>
            <span />
            <ButtonsWrapper>
                <HyperLink onClick={() => nav("/recover", { state: { user } })}>
                    Esqueci minha senha
                </HyperLink>
                <div>
                    <LoginButton
                        style={{ backgroundColor: "#e8e4db" }}
                        onClick={Register}
                    >
                        <p style={{ color: "#713217", paddingInline: 10 }}>
                            CADASTRAR
                        </p>
                    </LoginButton>
                    <span style={{ margin: 5 }} />
                    <LoginButton
                        disabled={logging}
                        className={logging ? "progress" : ""}
                        onClick={Login}
                    >
                        <EnterLabel />
                    </LoginButton>
                </div>
            </ButtonsWrapper>

            <Divider />

            <GoogleButton title="Login com Google" onClick={props.googleAuth} />
        </FormPanel>
    )
}

export default Login
