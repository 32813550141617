import React, { useState } from "react"
import TrainerPlans, { Plan } from "src/modules/TrainerCheckout/TrainerPlans"
import { CheckoutResume, Container, Header, Scroll } from "./styles"
import { CgChevronRight } from "react-icons/cg"
import { BiChevronLeft } from "react-icons/bi"
import { useOverlay } from "src/screens/checkout/context"
import { loadStripe } from "@stripe/stripe-js"
import api from "src/rest/api"
import { LineWave } from "react-loader-spinner"

const PlansOver: React.FC = () => {
    const [plan, setPlan] = useState<Plan>()
    const [loading, setLoading] = useState(false)
    const over = useOverlay()

    async function Checkout() {
        try {
            setLoading(true)
            const key = await api.post<{ publishableKey: string }>(
                "/stripe/config"
            )

            const stripe = await loadStripe(key.data.publishableKey)

            const session = await api.post<{ session: { id: string } }>(
                "/stripe/session-payment",
                { plan: plan?.id }
            )

            stripe?.redirectToCheckout({
                sessionId: session.data.session.id,
            })
        } catch (err) {
            alert(err)
        } finally {
            setLoading(false)
        }
    }

    if (loading)
        return (
            <Container className="flex">
                <LineWave color="white" />
            </Container>
        )

    return (
        <Container className="flex">
            <Scroll>
                <Header onClick={over.dismiss}>
                    <BiChevronLeft size={32} />
                    <h1>Contratar Plano</h1>
                </Header>
                <TrainerPlans
                    selected={plan}
                    setSelected={setPlan}
                    height={300}
                    width={250}
                />
                {plan && (
                    <CheckoutResume>
                        <div>
                            <h2>{plan.name}</h2>
                            <h3>{(plan.value / 100).toFixed(2)}</h3>
                            {plan.active !== "soon" ? (
                                <p>{plan.description}</p>
                            ) : (
                                <p>Este plano estará disponível em breve!</p>
                            )}
                        </div>

                        <div style={{ flex: 1, flexDirection: "row-reverse" }}>
                            <button disabled={loading} onClick={Checkout}>
                                <p>PAGAMENTO</p> <CgChevronRight />
                            </button>
                        </div>
                    </CheckoutResume>
                )}
            </Scroll>
        </Container>
    )
}

export default PlansOver
