import React from "react"
import styled from "styled-components"
import plans from "../../../screens/checkout/plans"
import { useQuery } from "react-query"
import api from "src/rest/api"
import { MdCheckCircle } from "react-icons/md"

export interface Plan {
    id: string
    active: "true" | "soon"
    name: string
    value: number
    description: string
}

// import { Container } from './styles';

const Container = styled.div.attrs({
    class: "flex",
})`
    flex-direction: row;

    justify-content: center;
`

const Quad = styled.div`
    flex: 1;

    flex-direction: column;

    justify-content: space-between;

    border: 1px solid #ccc;
    background-color: #fff;

    height: 100%;

    padding: 8px;
    border-radius: 8px;

    :not(:last-child) {
        margin-right: 10px;
    }
`

const SelectButton = styled.button<{ color: string }>`
    cursor: pointer;
    border: 1px solid ${(p) => p.color};
    color: ${(p) => p.color};
    padding: 5px;
    border-radius: 50px;
    background-color: transparent;

    :disabled {
        background-color: ${(p) => p.color};
        color: white;
    }
`

const Description = styled.ul`
    flex-direction: column;

    li {
        padding: 5px;
        font-size: 12px;
        justify-content: space-between;
        border-radius: 5px;
    }

    li:nth-child(odd) {
        background-color: #eee;
    }
`

const Pricing = styled.div`
    flex-direction: row;
    width: 100%;

    align-items: center;
    justify-content: center;

    h1 {
        display: flex;
        flex-direction: row;
        width: 100%;

        font-weight: 600;
    }
`

const Select = styled.p`
    cursor: pointer;
    :hover {
        transform: scale(1.1);
    }

    transition: transform 0.1s ease-in;

    background-color: transparent;
    flex-direction: column;

    height: 100%;

    :not(:last-child) {
        margin-right: 20px;
    }
`

const PlanItem: React.FC<{
    selected: boolean
    id: string
    name: string
    active: string
    price: number
}> = (props) => {
    return (
        <Quad
            style={{
                borderColor: props.selected ? "#0a0" : "#ddd",
                width: "100%",
            }}
        >
            <div style={{ alignItems: "center", fontSize: 18 }}>
                {props.selected && (
                    <MdCheckCircle
                        color="#0a0"
                        style={{
                            marginTop: 3,
                            marginRight: 10,
                        }}
                    />
                )}
                <h4>{props.name}</h4>
            </div>

            <Pricing>
                {props.active === "soon" ? (
                    <>
                        <h1>EM BREVE</h1>
                    </>
                ) : (
                    <>
                        <h1>R$ {(props.price / 100).toFixed(2)}</h1>
                        <p>BRL</p>
                    </>
                )}
            </Pricing>

            <Description>
                {plans.map((e, i) => (
                    <li key={`pl_${i}`}>
                        <p>{e.title}</p>
                        <MdCheckCircle color="#0a0" />
                    </li>
                ))}
            </Description>

            <div
                style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {props.active === "soon" ? (
                    <SelectButton color="black">EM BREVE</SelectButton>
                ) : (
                    <SelectButton color="#0b0" disabled={props.selected}>
                        {props.selected ? "SELECIONADO" : "SELECIONAR"}
                    </SelectButton>
                )}
            </div>
        </Quad>
    )
}

export const PlanInfo: React.FC<{ plan: Plan | undefined }> = ({ plan }) => {
    if (!plan) return <p>Invalid Metadata</p>

    return (
        <div style={{ minHeight: 300 }}>
            <PlanItem
                selected={false}
                id={plan.id}
                name={plan.name}
                price={plan.value}
                active={plan.active}
            />
        </div>
    )
}

const TrainerPlans: React.FC<{
    selected: Plan | undefined
    setSelected: (id: Plan | undefined) => void
    height?: number
    width?: number
}> = ({ selected, setSelected, height, width }) => {
    const { data } = useQuery("trainerPlans", () =>
        api
            .post<Plan[]>("/trainerplans/list")
            .then((res) => {
                return res.data
            })
            .catch(() => [])
    )

    function select(e: Plan) {
        if (e.active === "true") setSelected(e)
    }

    return (
        <Container>
            {data &&
                data.map((e) => {
                    return (
                        <Select
                            key={e.id + "plan"}
                            onClick={() => select(e)}
                            style={{ height, width }}
                        >
                            <PlanItem
                                selected={selected?.id === e.id}
                                id={e.id}
                                name={e.name}
                                price={e.value}
                                active={e.active ?? "soon"}
                            />
                        </Select>
                    )
                })}
        </Container>
    )
}

export default TrainerPlans
