export function limitInput(e: React.KeyboardEvent<HTMLInputElement>) {
    const { value, maxLength } = e.currentTarget

    if (!maxLength) return

    const sel = window.getSelection()?.type === "Range"

    if (
        value.length >= maxLength &&
        e.key.length === 1 && // Somente caracteres imprimíveis
        !e.ctrlKey &&
        !sel
    ) {
        e.preventDefault()
    }
}
