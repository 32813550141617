import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100vw;
    max-width: 1100px;
    flex-direction: column;

    border: 1px solid #ddd;
    padding: 15px;

    border-radius: 10px;
`

export const PlanTitle = styled.h2`
    color: #333;
    font-size: 24px;
`

export const PlanDetails = styled.div`
    flex-direction: column;

    margin-top: 15px;
    font-size: 16px;
    color: #555;
`

export const Subscribe = styled.button`
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;

    margin-top: 10px;

    background-color: #0b0;

    color: white;
    font-weight: bold;
`

export const PlanItem = styled.div`
    margin-bottom: 8px;

    p {
        font-size: 12px;
    }

    strong {
        font-size: 12px;
        color: #000;
        font-weight: bold;
        padding-right: 10px;
    }
`

export const Container = styled.div`
    display: flex;
    flex: 1;

    flex-direction: column;

    @media (min-width: 768px) {
        justify-content: center;
        align-items: center;
        padding: 5px;
    }

    overflow-y: auto;
`

export const Row = styled.div`
    width: 100%;
    background-color: #efefef;

    border-radius: 10px;
`

export const Section = styled.div`
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 10px;
`

export const OutlineButton = styled.button<{ color: `#${string}` }>`
    background-color: transparent;
    border: 1px solid ${(p) => p.color};
    color: ${(p) => p.color};

    padding: 10px;
    border-radius: 5px;

    transition: background-color 0.05s;

    cursor: pointer;

    :active {
        background-color: ${(p) => p.color + "4"};
    }
`
