import React, { createContext, ReactNode, useContext, useState } from "react"
import Dialog from "src/components/dialog-static"

interface DialogStatics {
    openDialog: (
        title: string,
        subtitle: string,
        confirmAction?: () => void,
        confirmText?: string,
        okOnly?: boolean
    ) => void
    closeDialog: () => void
}

interface DialogAction {
    title: string
    subtitle: string
    confirmAction?: () => void
    confirmText?: string
    okOnly?: boolean
}

const DialogContext = createContext<DialogStatics | undefined>(undefined)

const DialogProvider: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const [info, setInfo] = useState<DialogAction | null>(null)

    const openDialog = (
        title: string,
        subtitle: string,
        confirmAction?: () => void,
        confirmText?: string,
        okOnly?: boolean
    ) => {
        setInfo({ title, subtitle, confirmAction, confirmText, okOnly })
    }

    const confirm = () => {
        if (info?.confirmAction) {
            try {
                info.confirmAction()
            } finally {
                closeDialog()
            }
        } else {
            closeDialog()
        }
    }

    const closeDialog = () => {
        setInfo(null)
    }

    return (
        <DialogContext.Provider value={{ openDialog, closeDialog }}>
            {children}
            {info && (
                <Dialog
                    title={info.title}
                    subtitle={info.subtitle}
                    confirmText={info.confirmText}
                    onConfirm={confirm}
                    onCancel={closeDialog}
                    okOnly={info.okOnly}
                />
            )}
        </DialogContext.Provider>
    )
}

const useDialog = () => {
    const context = useContext(DialogContext)
    if (!context) {
        throw new Error("useDialog must be used within a DialogProvider")
    }
    return context
}

export { DialogProvider, useDialog }
