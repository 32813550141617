import React, { useEffect, useState } from "react"
import MutableIcon from "src/components/mutable-icon"
import { AddNoteButton, Note, Notes } from "../styles"
import { MdAddCircle, MdDeleteForever } from "react-icons/md"
import { useStudent } from "src/contexts/student"
import { nanoid } from "nanoid"
import mostrarAlerta from "src/components/utils/alert"
import api from "src/rest/api"
import { LineWave } from "react-loader-spinner"
import note from "../../../assets/svg/rectangle.svg"

// import { Container } from './styles';

const StudentNotes: React.FC = () => {
    const { student } = useStudent()

    const [notes, setNotes] = useState<string[]>()

    useEffect(() => {
        if (!notes)
            api.post("/students/getnotes", { ref: student?.id }).then((res) =>
                setNotes(res.data)
            )
    }, [])

    function UpdateNote(e: React.ChangeEvent<HTMLTextAreaElement>) {
        if (!notes) return

        const { name, value } = e.target

        setNotes((old) => {
            const n = [...(old ?? [])]
            n[parseInt(name)] = value
            return n
        })
    }

    function RemoveNote(index: number) {
        if (!notes) return

        const newNotes = [...notes]

        try {
            newNotes.splice(index, 1)
            setNotes(newNotes)
        } finally {
            UploadNote(newNotes)
        }
    }

    function AddNote() {
        if (!notes) return

        const newNotes = [...notes, ""]
        setNotes(newNotes)
        UploadNote(newNotes)
    }

    function UploadNote(data: string[]) {
        console.log("uploading notes:", data)
        if (!student) return
        api.post("/students/savenotes", {
            ref: student.id,
            data: data,
        }).catch((err) => {
            mostrarAlerta(err)
        })
    }

    if (!notes) {
        return (
            <Notes
                style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <LineWave color="black" width={80} height={80} />
            </Notes>
        )
    } else {
        return (
            <Notes>
                <div>
                    <img src={note} alt="" />
                    <h4>Notas</h4>
                </div>

                <ul>
                    {notes &&
                        notes.map((e, index) => (
                            <Note key={`note_${index}`}>
                                <div
                                    style={{
                                        margin: 0,
                                        padding: 5,
                                    }}
                                >
                                    <p>Nota {index + 1}</p>
                                    {index != 0 && (
                                        <MutableIcon
                                            onClick={() => RemoveNote(index)}
                                            icon={MdDeleteForever}
                                            color="#444"
                                            size={24}
                                        />
                                    )}
                                </div>
                                <textarea
                                    name={index.toString()}
                                    key={`note_${index}`}
                                    id={nanoid()}
                                    value={e}
                                    placeholder="Escreva notas e lembretes do aluno..."
                                    onBlur={() => UploadNote(notes)}
                                    onChange={UpdateNote}
                                />
                            </Note>
                        ))}

                    <div
                        style={{
                            flexDirection: "row-reverse",
                        }}
                    >
                        <AddNoteButton onClick={AddNote}>
                            <MdAddCircle />
                        </AddNoteButton>
                    </div>
                </ul>
            </Notes>
        )
    }
}

export default StudentNotes
