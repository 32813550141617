import styled from "styled-components"

export const Scroll = styled.ul`
    display: flex;
    flex: 1 0 1;

    height: 100%;

    flex-direction: column;

    overflow-y: scroll;
    overflow-x: hidden;

    div {
        flex-direction: row;
    }
`

export const List = styled.div`
    display: flex;
    flex: 1;

    padding: 10px;

    overflow: hidden;

    flex-direction: column;
`

export const ListHeader = styled.div`
    display: flex;
    align-items: center;

    justify-content: space-between;

    margin-bottom: 30px;

    h3 {
        padding: 10px;
        margin: 0px;
        font-weight: 700;
    }
`

export const SearchField = styled.div`
    flex-direction: column;
    justify-content: center;

    align-items: center;
    margin-inline: 30px;
    height: 80px;
    width: 100%;
`

export const CreateButton = styled.button`
    border-radius: 25px;
    justify-content: space-between;

    padding: 8px;
    font-size: 16px;
    width: 200px;
    background-color: grey;
    color: white;

    :enabled {
        :hover {
            opacity: 0.8;
        }
        background-color: var(--primary);
        cursor: pointer;
    }
`

export const AddForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    justify-content: center;

    span {
        margin: 5px;
    }

    select {
        width: 50%;
        border-radius: 5px;
        border-color: #ccc;
    }
`

export const Item = styled.button<{ disabled: boolean }>`
    position: relative;

    display: flex;
    flex-direction: row;

    margin-bottom: 10px;

    border-radius: 5px;

    transition: background-color 0.025s;

    background-color: #eee;

    ${(p) =>
        !p.disabled &&
        `
        cursor: pointer;
       
        :hover {
            background: #ddd;
        }
    `}

    img {
        height: 45px;
        margin-block: 5px;
    }

    p {
        margin-left: 10px;
    }

    h5 {
        color: #00c013;
    }

    align-items: center;

    div {
        width: 100%;
        padding: 10px;
        justify-content: space-between;
    }

    width: 100%;
`
