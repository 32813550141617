import styled from "styled-components"

export const Container = styled.div`
    position: absolute;

    flex-direction: column;

    z-index: 99;

    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    display: flex;
    flex: 1;

    height: 100vh;
    width: 100vw;

    justify-content: center;
    align-items: center;

    background: rgb(66, 66, 66);
    background: radial-gradient(
        circle,
        rgba(66, 66, 66, 0) 0%,
        rgba(0, 0, 0, 1) 100%
    );

    overflow-y: auto;
`

export const Header = styled.div`
    color: white;
    align-items: center;

    padding-bottom: 25px;

    width: 100%;

    h1 {
        margin-left: 10px;
    }

    :hover {
        opacity: 0.9;
    }
    cursor: pointer;
`

export const CheckoutResume = styled.div`
    flex-direction: row;
    background-color: #fff;
    border-radius: 8px;

    margin-top: 20px;
    padding: 10px;

    min-width: 500px;

    > div {
        flex-direction: column;
        width: 100%;
        justify-content: center;

        button {
            width: fit-content;
            border: 1px solid #0b0;
            background-color: transparent;
            padding: 5px;

            border-radius: 5px;

            align-items: center;

            cursor: pointer;

            transition: background-color 0.1s, color 0.1s;
        }

        button:active {
            background-color: #0b0;
            color: white;
        }
    }
`

export const Scroll = styled.div`
    flex-direction: column;
    padding: 20px;
`
