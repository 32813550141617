import React from "react"
import { Student } from "src/screens/student-info"
import { StudentList } from ".."
import { Avatar } from "src/components/header/styles"
import student, { useStudent } from "src/contexts/student"
import { Item } from "../styles"
import dummy from "../../../assets/png/userd-demo-pic.png"
import {
    GetStudentStatus,
    studentStatus,
} from "src/screens/student-info/student-status"
// import { Container } from './styles';

const Students: React.FC<{
    list: StudentList[]
    onSelect(s: StudentList): void
}> = ({ list, onSelect }) => {
    const student = useStudent()

    GetStudentStatus

    return (
        <>
            {list.map((element, index) => {
                return (
                    <Item
                        key={`${index}`}
                        disabled={element.id === student.student?.id}
                        onClick={() => onSelect(element)}
                    >
                        <Avatar
                            src={element.photourl ?? dummy}
                            alt={dummy}
                            style={{ height: 40, width: 40 }}
                        />
                        <div>
                            <p>{element.name}</p>
                            <h5
                                style={{
                                    color: studentStatus[element.status].color,
                                }}
                            >
                                {studentStatus[element.status].label}
                            </h5>
                        </div>
                    </Item>
                )
            })}
        </>
    )
}

export default Students
