import React, { useContext, useEffect } from "react"
import { Route, useNavigate } from "react-router-dom"
import { StyledRoutes } from "src/assets/GlobalStyles"
import Header from "src/components/header"
import AuthContext, { useAuth } from "src/contexts/auth"
import Anamnese from "src/screens/anamnese"
import Assessment from "src/modules/assessment"
import StudentInfo from "src/screens/student-info"
import WorkoutTemplates from "src/screens/templates/workout"
import { StudentProvider } from "src/contexts/student"
import AssessmentComparision from "src/modules/assessment/AssessmentComparision"
import { DrawerProvider } from "src/contexts/drawer-context/Drawer"
import DashboardGrid from "src/screens/dashboardGrid"
import CalendarScreen from "src/screens/calendar"
import ChargeProgression from "src/screens/ChargeProgression"
import Plan from "src/modules/protocols/Plan"
import PlanTemplates from "src/modules/protocols/Plan/Templates"
import { DialogProvider } from "src/contexts/dialog-context"
import StudentSettings from "src/screens/student-info/Settings"
import Checkout from "src/screens/checkout"
import moment from "moment"
import NewExercises from "src/screens/templates/new-exercises"
import { CenteredDiv } from "src/screens/calendar/styling/styles"
import { TbError404 } from "react-icons/tb"
import { GetParameter } from "src/assets/functions"
import styled from "styled-components"

import { Storage } from "src/modules/fithub-shared/TokenManager/data-storage"
import { altToken } from "src/rest/api"

type TokenErrors = "NOT_AUTHORIZED" | "RENEWING" | "EXPIRED" | undefined

const ReloadButton = styled.button`
    padding: 10px;
    border-radius: 5px;

    background-color: var(--primary);
    color: white;
    font-weight: bold;
    margin-top: 25px;

    cursor: pointer;
`

export function ExpiredPage() {
    const navigate = useNavigate()

    const error = GetParameter("error") as TokenErrors
    const ref = GetParameter("ref")

    function ForceLogout() {
        try {
            Storage.remove("@auth:user")
            altToken.cleanUp()
        } finally {
            navigate("/")
        }
    }

    function ReloadPage() {
        window.location.reload()
        window.location.href = "/"
    }

    if (error === "RENEWING" && ref === "valid") {
        return (
            <CenteredDiv
                style={{
                    display: "flex",
                    flex: 1,
                    height: "100vh",
                    backgroundColor: "#eee",
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <TbError404 size={48} />
                    <p>Erro de conexão com o servidor</p>

                    <ReloadButton onClick={ReloadPage}>
                        Recarregar Página
                    </ReloadButton>
                </div>
            </CenteredDiv>
        )
    } else
        return (
            <CenteredDiv
                style={{
                    display: "flex",
                    flex: 1,
                    height: "100vh",
                    backgroundColor: "#eee",
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <TbError404 size={48} />
                    <p>Sessão Expirada!</p>

                    <ReloadButton onClick={ForceLogout}>
                        Voltar ao Login
                    </ReloadButton>
                </div>
            </CenteredDiv>
        )
}

export function LogoutRoute() {
    const navigate = useNavigate()

    useEffect(() => {
        Storage.remove("@auth:user")
        altToken.cleanUp()
        navigate("/")
    }, [])

    return (
        <CenteredDiv style={{ backgroundColor: "#ddd" }}>
            <div>Sessão Expirada!</div>
        </CenteredDiv>
    )
}

// import { Container } from './styles';
export const SignedRoutes: React.FC = () => {
    const context = useContext(AuthContext)

    moment.locale("ptbr")

    if (!context.user) {
        return <p>Sessão Inválida!</p>
    } else
        return (
            <StudentProvider>
                <DialogProvider>
                    <DrawerProvider>
                        {context.user && <Header {...context.user} />}
                        <StyledRoutes>
                            <Route path="/" element={<DashboardGrid />} />
                            <Route path="/student" element={<StudentInfo />} />
                            <Route
                                path="/calendar"
                                element={<CalendarScreen />}
                            />

                            <Route
                                path="/tools/exercises"
                                element={<NewExercises />}
                            />
                            <Route
                                path="/tools/workout"
                                element={<WorkoutTemplates />}
                            />
                            <Route
                                path="/tools/plans"
                                element={<PlanTemplates />}
                            />
                            <Route
                                path="/student/workouts"
                                element={<Plan />}
                            />

                            <Route
                                path="/student/settings"
                                element={<StudentSettings />}
                            />

                            <Route
                                path="/student/progression"
                                element={<ChargeProgression />}
                            />

                            <Route
                                path="/student/assessments"
                                element={<Assessment />}
                            />
                            <Route
                                path="/student/assessments/compare"
                                element={<AssessmentComparision />}
                            />
                            <Route
                                path="/tools/anamnese"
                                element={<Anamnese />}
                            />
                            <Route path="/logout" element={<LogoutRoute />} />
                            <Route path="/expired" element={<ExpiredPage />} />
                            <Route path="/checkout" element={<Checkout />} />
                        </StyledRoutes>
                    </DrawerProvider>
                </DialogProvider>
            </StudentProvider>
        )
}
