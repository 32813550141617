import React, { useRef } from "react"
import { Container } from "./styles"
import { useOverlay } from "src/screens/checkout/context"
import { useOnOutClick } from "src/components/hooks"
import api from "src/rest/api"
import { useStudent } from "src/contexts/student"
import { reload } from "firebase/auth"
import { useNavigate } from "react-router-dom"

// import { Container } from './styles';

const UnlinkWarn: React.FC<{
    student: string
    email: string
    reload?: () => void
}> = ({ reload, student, email }) => {
    const cont = useRef<HTMLDivElement>(null)
    const over = useOverlay()

    const nav = useNavigate()

    useOnOutClick(cont, over.dismiss)

    async function ConfirmUnlink() {
        try {
            await api.post("/student/unlink", { ref: student, email })
            over.dismiss()
            reload && reload()
            nav("/")
        } catch (err) {
            alert(JSON.stringify(err))
        }
    }

    return (
        <Container ref={cont} className="flex">
            <h2>Aviso!</h2>
            <span style={{ margin: 5 }} />
            <h5>
                Ao desvincular um aluno, você estará excluindo TODOS OS DADOS
                prescritos para este aluno, presentes neste vínculo.
            </h5>
            <span style={{ margin: 5 }} />

            <h5>
                Para confirmar esta ação, escreva o email do aluno no campo
                abaixo:
            </h5>
            <span style={{ margin: 5 }} />
            <div>
                <input placeholder={email} />
                <button onClick={ConfirmUnlink}>Confirmar</button>
            </div>
        </Container>
    )
}

export default UnlinkWarn
