import React, { useContext, useState } from "react"
import { Wrapper } from "./styles"
import AssessmentList from "./listing"

import AssessmentContent from "./AssessmentContent"
import StudentContext, { useStudent } from "src/contexts/student"
import { AssessmentContextProvider } from "./context/assessmentContext"
import Dialog from "src/components/dialog"
import SideListView from "src/components/SideListView"
import api from "src/rest/api"
import { AssessmentClass } from "../fithub-shared/anthropometry/assessment"
import { useQuery } from "react-query"
import { useDialog } from "src/contexts/dialog-context"
// import { Container } from './styles';

const Assessment: React.FC = () => {
    const [selected, setSelected] = useState("")
    const [dialog, setDialog] = useState(false)
    function onSetSelected(s: string) {
        setSelected("")
        setTimeout(() => {
            setSelected(s)
        }, 100)
    }

    const student = useStudent().student

    const { data, isLoading, refetch } = useQuery(
        ["assessmentList", student?.id],
        () =>
            api
                .post<AssessmentClass[]>("/assessment/list", {
                    user_id: student?.id,
                })
                .then((res) => res.data)
                .catch((err) => {
                    console.error(err)
                    return []
                })
    )

    const dl = useDialog()

    function handleDelete() {
        dl.openDialog("Excluir", "Deseja excluir esta avaliação?", Delete)
    }

    function Delete() {
        api.post("/assessment/delete", {
            id: selected,
        }).then(() => {
            setSelected("")
            refetch()
        })
    }

    return (
        <AssessmentContextProvider>
            <Wrapper className="flex row">
                <SideListView
                    list={
                        <AssessmentList
                            onSetSelected={onSetSelected}
                            handleDelete={handleDelete}
                            selected={selected}
                            Reload={refetch}
                            list={data}
                        />
                    }
                    content={
                        selected && (
                            <AssessmentContent
                                id={selected}
                                handleUnselect={() => onSetSelected("")}
                                handleReload={refetch}
                                handleDelete={handleDelete}
                            />
                        )
                    }
                    view={selected ? "CONTENT" : "LIST"}
                />
            </Wrapper>
        </AssessmentContextProvider>
    )
}

export default Assessment
