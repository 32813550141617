import {
    UseMutationResult,
    useQueryClient,
    useMutation,
    useQuery,
} from "react-query"
import mostrarAlerta from "src/components/utils/alert"
import {
    AssessmentClass,
    AssessmentObject,
} from "src/modules/fithub-shared/anthropometry/assessment"
import api from "src/rest/api"

export const useGetAssessment = (id: string) => {
    return useQuery(["assessment", id], () =>
        api
            .post("assessment/get", { id: id })
            .then((res) => res.data as AssessmentClass)
    )
}

type compare = AssessmentClass | undefined

export const useCompareAssessment = (a: string, b: string) => {
    return useQuery(["aComparision"], async () => {
        const contentA = a
            ? await api
                  .post("assessment/get", { id: a })
                  .then((res) => res.data as AssessmentClass)
                  .catch((err) => err)
            : undefined

        const contentB = b
            ? await api
                  .post("assessment/get", { id: b })
                  .then((res) => res.data as AssessmentClass)
                  .catch((err) => err)
            : undefined

        return { a: contentA as compare, b: contentB as compare }
    })
}

export const useCreateAssessment = (
    target?: string
): UseMutationResult<void, Error, void, unknown> => {
    const queryClient = useQueryClient()

    return useMutation<void, Error, void, unknown>(
        async () => {
            await api.post("/assessment/create", {
                user_id: target,
                content: new AssessmentObject(),
            })
        },
        {
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                console.log("success fetching")
                queryClient.refetchQueries(["assessmentList", target])
            },
        }
    )
}

export const useDuplicateAssessment = (
    id?: string,
    student?: string
): UseMutationResult<void, Error, void, unknown> => {
    const queryClient = useQueryClient()

    return useMutation<void, Error, void, unknown>(
        async () => {
            await api.post("/assessment/duplicate", {
                id,
            })
        },
        {
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                queryClient.refetchQueries(["assessmentList", student])
            },
        }
    )
}

export const useUpdateAssessment = (
    id: string
): UseMutationResult<void, Error, AssessmentClass, unknown> => {
    const queryClient = useQueryClient()

    return useMutation<void, Error, AssessmentClass, unknown>(
        async (assessment) => {
            await api.post("/assessment/update", assessment)
        },
        {
            onMutate: async (variables) => {
                return variables
            },
            onError: (error) => {
                console.error("Erro na mutação:", error)
            },
            onSuccess: () => {
                mostrarAlerta("Salvo!")
                queryClient.refetchQueries(["assessment", id])
                queryClient.refetchQueries(["assessmentList", id])
            },
        }
    )
}
