import React from "react"
import {
    Avatar,
    CTAButton,
    Header,
    TextWrapper,
    UserInfo,
} from "../../Plan/View/protocols-listing/styles"
import { HiChevronLeft } from "react-icons/hi"
import MutableIcon from "src/components/mutable-icon"
import { useNavigate } from "react-router-dom"

import dummy from "../../../../assets/png/userd-demo-pic.png"
import { useStudent } from "src/contexts/student"
import { IconType } from "react-icons"
import { RiAddCircleFill } from "react-icons/ri"

// import { Container } from './styles';

interface ListInfoProps {
    children?: React.ReactElement
    create?: () => void
    subtitle?: string
    data:
        | {
              icon: IconType
              title: string
          }
        | undefined
}

const ListInfoHeader: React.FC<ListInfoProps> = ({
    children,
    create,
    data,
    subtitle = "",
}) => {
    const navigate = useNavigate()

    const context = useStudent()

    return (
        <Header>
            {data === undefined ? (
                <>
                    <UserInfo>
                        <div style={{ alignItems: "center" }}>
                            <MutableIcon
                                icon={HiChevronLeft}
                                size={40}
                                onClick={() => navigate(-1)}
                            />
                            <Avatar src={context.student?.photourl ?? dummy} />
                        </div>

                        <TextWrapper>
                            <h1>{context.student?.name}</h1>
                            <h5>{subtitle}</h5>
                        </TextWrapper>
                    </UserInfo>
                </>
            ) : (
                <>
                    <UserInfo style={{ justifyContent: "space-between" }}>
                        <div style={{ alignItems: "center" }}>
                            <MutableIcon
                                icon={HiChevronLeft}
                                size={40}
                                onClick={() => navigate(-1)}
                            />
                            <data.icon
                                style={{
                                    backgroundColor: "#ddd",
                                    borderRadius: "50%",
                                    padding: 10,
                                    marginRight: 10,
                                }}
                                color="#666"
                                size={48}
                            />
                        </div>

                        <TextWrapper>
                            <h1>{data.title}</h1>
                            <h5>{subtitle}</h5>
                        </TextWrapper>

                        {create && (
                            <CTAButton onClick={create}>
                                <RiAddCircleFill />
                                <h4>CRIAR</h4>
                            </CTAButton>
                        )}
                    </UserInfo>
                </>
            )}
            {children && children}
        </Header>
    )
}

export default ListInfoHeader
