import React from "react"
import { IconType } from "react-icons"
import { CenteredDiv } from "src/screens/calendar/styling/styles"
import styled from "styled-components"

// import { Container } from './styles';

interface EmptyListInfoProps {
    Icon: IconType
    type: string
    customText?: string
    hyperlink?: {
        text: string
        action?: () => void
    }
}

const EmptyContent = styled.div`
    flex-direction: column;
    align-items: center;

    strong {
        font-weight: 600;
    }

    > h5 {
        font-size: 16px;
        padding: 5px;
        text-align: center;

        margin-top: 15px;
        margin-bottom: 5px;

        background-color: #eee;
        border-radius: 8px;
    }

    > a {
        font-size: 14px;
        text-decoration: underline;
        padding: 2px;

        cursor: pointer;

        :active {
            opacity: 0.5;
        }
    }
`

const EmptyListInfo: React.FC<EmptyListInfoProps> = ({
    Icon,
    type,
    hyperlink,
    customText,
}) => {
    function Action() {
        if (hyperlink && hyperlink.action) hyperlink.action()
    }

    return (
        <CenteredDiv>
            <EmptyContent>
                <Icon size={30} />
                <h5 style={{ fontSize: 14 }}>
                    {!customText ? (
                        <>
                            Listagem de <strong>{type}</strong>
                            <br />
                            está vazia!
                        </>
                    ) : (
                        customText
                    )}
                </h5>
                {hyperlink && (
                    <a onClick={Action}>
                        {hyperlink.text} <strong>{type}</strong>
                    </a>
                )}
            </EmptyContent>
        </CenteredDiv>
    )
}

export default EmptyListInfo
