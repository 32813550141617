import React from "react"

import { useNavigate } from "react-router-dom"

import {
    BasicInfo,
    ButtonsLayout,
    Column,
    StudentWrapper,
    AditionalInfo,
    Avatar,
    ConfigButton,
    NotificationsLayout,
    LabeledButton,
} from "./styles"

import dummy from "../../assets/png/userd-demo-pic.png"
import { LargeButtonInfo } from "../../components/LargeButton"
import { HiChevronLeft } from "react-icons/hi"
import MutableIcon from "../../components/mutable-icon"
import mostrarAlerta from "../../components/utils/alert"
import { MdFolder, MdPhoneAndroid } from "react-icons/md"
import moment from "moment"
import { FaBirthdayCake, FaCog, FaLink } from "react-icons/fa"
import { TbActivityHeartbeat } from "react-icons/tb"
import Activities from "./Activities"
import { LineWave } from "react-loader-spinner"
import { CenteredDiv } from "../calendar/styling/styles"
import { useStudent } from "src/contexts/student"
import StudentNotes from "./Notes"
import api from "src/rest/api"
import { GetStudentStatus } from "./student-status"

export interface Student {
    id: string
    name: string
    username: string
    email: string
    phone: string
    gender: string
    birthdate: Date
    created_at: Date
    photourl?: string
    status: "active" | "blocked"
    //activities: Array<Activity>
    //notes?: string[]
}

const fallbackCopyTextToClipboard = (text: string) => {
    // Abre um prompt para o usuário copiar o link manualmente
    const copyPrompt = `Copie o link abaixo, e envie ao seu aluno. No primeiro acesso, a criação de senha será solicitada!`
    window.prompt(copyPrompt, text)
}

export default function StudentInfo() {
    const navigate = useNavigate()

    const { student } = useStudent()

    async function GetTraineeLink() {
        if (!student) return

        mostrarAlerta("Gerando Link...")

        try {
            const encodedId = await api.post("/students/get-link", {
                ref: student.id,
            })

            const link = `https://aluno.fithub.app.br/aluno?id=${encodedId.data}`

            if (navigator.clipboard && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(link)
                mostrarAlerta(
                    "Link do Aluno copiado para a Área de Transferência!"
                )
            } else {
                fallbackCopyTextToClipboard(link)
            }
        } catch (error) {
            mostrarAlerta("Erro ao gerar o link.")
            console.error("Erro ao gerar o link do aluno", error)
        }
    }

    const det = GetStudentStatus(student?.status)

    if (!student)
        return (
            <CenteredDiv>
                <LineWave color="black" width={80} height={80} />
            </CenteredDiv>
        )
    return (
        <StudentWrapper className="flex row">
            <Column>
                <BasicInfo>
                    <MutableIcon
                        icon={HiChevronLeft}
                        size={40}
                        onClick={() =>
                            navigate("/", { state: { keepModal: true } })
                        }
                    />
                    <div style={{ position: "relative" }}>
                        <Avatar src={student.photourl ?? dummy} />
                    </div>

                    <div
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 30,
                        }}
                    >
                        <div style={{ flexDirection: "column" }}>
                            <p>{student.name}</p>
                            <p
                                style={{
                                    fontSize: 12,
                                    color: "#666",
                                }}
                            >
                                {student.email}
                            </p>
                            <div
                                style={{
                                    paddingTop: 10,
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        alignItems: "center",
                                        fontSize: 12,
                                    }}
                                >
                                    <FaBirthdayCake />
                                    <span style={{ margin: 2 }} />
                                    <p>
                                        {moment(student.birthdate).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </p>
                                </div>
                                <span style={{ margin: 5 }} />
                                <div
                                    style={{
                                        alignItems: "center",
                                        fontSize: 12,
                                    }}
                                >
                                    <MdPhoneAndroid />
                                    <span style={{ margin: 2 }} />
                                    <p>{student.phone}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <LabeledButton>
                                <ConfigButton onClick={GetTraineeLink}>
                                    <FaLink size={20} color="#444" />
                                </ConfigButton>
                                <p>Link do Aluno</p>
                            </LabeledButton>

                            <LabeledButton>
                                <ConfigButton
                                    onClick={() => navigate("settings")}
                                >
                                    <FaCog size={20} color="#444" />
                                </ConfigButton>
                                <p>Opções</p>
                            </LabeledButton>
                        </div>
                    </div>
                </BasicInfo>
                <AditionalInfo>
                    <div>
                        <h4>Cadastro</h4>
                        <h5 style={{ color: det.color }}>{det.label}</h5>
                    </div>
                    <span />
                    <div>
                        <h4>Aluno Desde</h4>
                        <h5>
                            {moment(student.created_at)
                                .format("MMMM [de] YYYY")
                                .toUpperCase()}
                        </h5>
                    </div>
                </AditionalInfo>
                <StudentNotes />
            </Column>
            <span style={{ margin: 5 }} />
            <Column forceSize>
                <NotificationsLayout>
                    <div>
                        <div
                            style={{
                                alignItems: "center",
                                paddingBottom: 5,
                                color: "#444",
                            }}
                        >
                            <TbActivityHeartbeat style={{ marginRight: 5 }} />
                            <h4 style={{ fontWeight: "bold" }}>Atividade</h4>
                        </div>

                        <Activities />

                        <ButtonsLayout>
                            <LargeButtonInfo
                                disabled
                                icon={MdFolder}
                                title={"Anamnese"}
                            />
                            <LargeButtonInfo
                                icon={MdFolder}
                                title={"Avaliações"}
                                onClick={() => navigate("assessments")}
                            />
                            <LargeButtonInfo
                                icon={MdFolder}
                                title={"Protocolos de Treinos"}
                                onClick={() => navigate("workouts")}
                            />
                            <LargeButtonInfo
                                icon={MdFolder}
                                title={"Progressão de Carga"}
                                onClick={() => navigate("progression")}
                            />
                        </ButtonsLayout>
                    </div>
                </NotificationsLayout>
            </Column>
        </StudentWrapper>
    )
}
