import React, { useState } from "react"
import { BiSearch, BiUser } from "react-icons/bi"
import { MdAddCircle } from "react-icons/md"
import MutableIcon from "../../../../../components/mutable-icon"
import {
    Browser,
    SearchBox,
    List,
    ExerciseListItem,
    CreateExerciseButton,
} from "../styles"
import { TemplatesB } from ".."
import { Queries } from "src/assets/queries/exerciseTemplates/queries"

import { useNewExerciseMutation } from "src/assets/queries/exerciseTemplates/mutations"
import { FaPersonBooth, FaYoutube } from "react-icons/fa"
import LoadErrorMessage from "../LoadErrorMessage"
import { FilterAndSortExc } from "src/screens/templates/exercise"
import convertYouTubeLinkToEmbed, {
    convertEmbedToYouTubeLink,
} from "src/modules/fithub-shared/functions/youtube-utils"
import { GiPerson } from "react-icons/gi"

// import { Container } from './styles';

const ExercisesTemplates: React.FC<TemplatesB> = (props) => {
    const [search, setSearch] = useState("")

    const { data, isLoading, error, refetch } = Queries.useExercisesList()

    function Search(t: string) {
        setSearch(t)
    }

    function Select(str: string, id: string) {
        props.onSelect(str, id)
        props.onDismis(false)
    }

    const newExercise = useNewExerciseMutation()

    async function Create() {
        await newExercise.mutateAsync(search)
    }
    const filtered = FilterAndSortExc(!data ? [] : data, search).slice(0, 80)

    if (!isLoading && filtered) {
        return (
            <Browser>
                <SearchBox>
                    <BiSearch />
                    <input
                        id="search-box"
                        autoFocus
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => Search(e.target.value)}
                        placeholder="Pesquisar Exercício..."
                    />
                </SearchBox>

                <List>
                    {filtered.map((element, index) => {
                        return (
                            <ExerciseListItem key={index}>
                                <button
                                    onClick={() =>
                                        Select(element.name, element.id)
                                    }
                                    style={{ flex: 1, height: "100%" }}
                                >
                                    <p>{element.name}</p>
                                </button>

                                {element.editable && (
                                    <BiUser
                                        size={32}
                                        style={{
                                            padding: 5,
                                            height: 30,
                                            width: 30,
                                            backgroundColor: "#ddd",
                                            borderRadius: 25,
                                        }}
                                    />
                                )}

                                <div style={{ alignItems: "center" }}>
                                    <button
                                        tabIndex={0}
                                        disabled={!element.hasmedia}
                                        style={{ paddingLeft: 10 }}
                                        onClick={() =>
                                            window.open(
                                                convertEmbedToYouTubeLink(
                                                    element.media_url
                                                ),
                                                "_blank",
                                                "noreferrer"
                                            )
                                        }
                                    >
                                        <FaYoutube size={20} />
                                    </button>
                                </div>
                            </ExerciseListItem>
                        )
                    })}
                </List>
                {search && (
                    <CreateExerciseButton onClick={Create}>
                        <MutableIcon icon={MdAddCircle} />
                        <p>Criar Exercício {`"${search}"`}</p>
                    </CreateExerciseButton>
                )}
            </Browser>
        )
    } else if (error) {
        return <LoadErrorMessage refetch={refetch} error={error} />
    } else {
        return <p>Carregando...</p>
    }
}

export default ExercisesTemplates
