import React, { useEffect } from "react"
import { Container } from "./styles"

import Login from "../login"
import { signInWithGoogle } from "src/rest/firebase"
import { useAuth } from "src/contexts/auth"
import { useNavigate } from "react-router-dom"

export interface LoginCredentials {
    email: string
    password: string
}

const LoginPage: React.FC = () => {
    const login = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        /*const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
        })

        // Cleanup subscription on unmount
        return () => unsubscribe()*/
    }, [])

    async function GoogleAuth() {
        const user = await signInWithGoogle()

        if (user !== null) {
            const status = await login.LoginGoogle(
                user.email ?? "",
                user.photoURL ?? ""
            )

            if (status === 201) {
                login.GSignIn.setUser(user)
                navigate("/register")
            }
        }
    }

    return (
        <Container
            className="flex column"
            style={{ flex: 1, backgroundColor: "#444", height: "100vh" }}
        >
            <Login googleAuth={GoogleAuth} />
        </Container>
    )
}

export default LoginPage
