import { jwtDecode } from "jwt-decode"

export function isTokenExpired(token: string | null): boolean {
    try {
        if (!token) return true

        const decoded: any = jwtDecode(token)
        const now = Date.now() / 1000

        return decoded.exp < now
    } catch {
        return true
    }
}
