import React, { useEffect, useState } from "react"
import Folds from "../components/folds"
import Bones from "../components/bones"
import DonutChart from "../components/DonutChart"
import {
    GetAntrophoGraphData,
    GraphData,
} from "src/modules/fithub-shared/anthropometry/antrophometry"

import { useDuplicateAssessment } from "src/modules/assessment/caching/queries"
import { useStudent } from "src/contexts/student"
import {
    Column,
    Content,
    ContentHeader,
    ContentView,
    ImageWrapper,
    LabelView,
    RoundButton,
    Scrollable,
} from "./styles"
import EditableText from "src/components/editable-text"
import { MdDelete, MdHeight, MdMonitorWeight } from "react-icons/md"
import Inputfield from "src/components/inputfield"
import Perimetry from "../components/perimetry"
import { PerimetryList } from "src/modules/fithub-shared/anthropometry/perimetry"
import { SkinFoldsList } from "src/modules/fithub-shared/anthropometry/folds"
import { BonesList } from "src/modules/fithub-shared/anthropometry/bones"
import moment from "moment"
import { BiCopy } from "react-icons/bi"
import { useAssessmentContext } from "../context/assessmentContext"
import { AssessmentClass } from "src/modules/fithub-shared/anthropometry/assessment"
import useLoadingButton from "../useLoadingButton"

interface AssessmentContentProps {
    id: string
    handleUnselect(): void
    handleReload(): void
    handleDelete(): void
}

const AssessmentContent: React.FC<AssessmentContentProps> = (props) => {
    const { student, age } = useStudent()
    const [antrop, setAntrop] = useState<AssessmentClass>()

    const button = useLoadingButton(handleSave)

    const { loadAssessment, save, setUnsaved, saved } = useAssessmentContext()

    const duplicate = useDuplicateAssessment(props.id)

    const [values, setValues] = useState<GraphData>({
        fat: 0,
        wastes: 0,
        muscles: 0,
    })

    useEffect(() => {
        if (!antrop) ReloadData(props.id)
    }, [props.id])

    async function ReloadData(id: string) {
        if (!student) return

        loadAssessment(id).then((res) => {
            if (res) {
                setAntrop(res)
                setValues(GetAntrophoGraphData(res, student.gender, age))
            }
        })
    }

    async function handleSave() {
        if (!student || !antrop) return

        save({
            ...antrop,
            graph: GetAntrophoGraphData(antrop, student.gender, age),
        } as AssessmentClass).then(() => {
            setValues(GetAntrophoGraphData(antrop, student.gender, age))
            button.changeState("LOADED")
        })
    }

    function handleDuplicate() {
        duplicate.mutateAsync().then(() => {
            props.handleUnselect()
            props.handleReload()
        })
    }

    function onChangeData() {
        if (!antrop || !student) return
        button.changeState("UNLOADED")
        setUnsaved()
        setValues(GetAntrophoGraphData(antrop, student.gender, age))
    }

    function handleChangeWeight(value: string) {
        if (!antrop) return

        const v = isNaN(parseFloat(value)) ? 0 : parseFloat(value)

        setAntrop({ ...antrop, weight: v })

        onChangeData()
    }

    function handleChangeHeight(value: string) {
        if (!antrop) return

        let v = isNaN(parseFloat(value)) ? 0 : parseFloat(value)

        if (v && v > 10) v = v / Math.pow(10, value.length - 1)

        setAntrop({ ...antrop, height: v })

        onChangeData()
    }

    function handleChangeTitle(e: string) {
        if (antrop) setAntrop({ ...antrop, title: e })

        onChangeData()
    }

    function handleChangeProtocol(protocol: number) {
        if (antrop)
            setAntrop({
                ...antrop,
                content: { ...antrop.content, protocol: protocol },
            })
        onChangeData()
    }

    function handleChangeFolds(folds: SkinFoldsList) {
        if (antrop)
            setAntrop({
                ...antrop,
                content: { ...antrop.content, folds: folds },
            })
        onChangeData()
    }

    function handleChangeBones(bones: BonesList) {
        if (antrop)
            setAntrop({
                ...antrop,
                content: { ...antrop.content, bones: bones },
            })
        onChangeData()
    }

    function handleChangePerimetry(perimetry: PerimetryList) {
        if (antrop)
            setAntrop({
                ...antrop,
                content: { ...antrop.content, perimetry: perimetry },
            })
        onChangeData()
    }

    if (!antrop) return <>Carregando...</>
    else
        return (
            <Content className="fade-in">
                <ContentHeader>
                    <EditableText
                        defaultValue={antrop.title}
                        onChange={handleChangeTitle}
                    />
                    <div style={{ alignItems: "center" }}>
                        <RoundButton onClick={handleDuplicate}>
                            <BiCopy size={24} />
                        </RoundButton>
                        <span style={{ margin: 5 }} />
                        <RoundButton onClick={props.handleDelete}>
                            <MdDelete size={24} />
                        </RoundButton>

                        {button.Button}
                    </div>
                </ContentHeader>
                <Scrollable>
                    <ContentView>
                        <h1>Informaçoes</h1>

                        <div
                            style={{
                                flexDirection: "row",
                                width: "100%",
                                maxWidth: 800,
                                justifyContent: "space-between",
                            }}
                        >
                            <Column style={{ justifyContent: "space-between" }}>
                                <ImageWrapper>
                                    <img
                                        src={require("../../../assets/images/human-male.jpg")}
                                    />
                                </ImageWrapper>
                                <div>
                                    <div
                                        style={{
                                            flexDirection: "column",
                                            flex: 1,
                                            paddingRight: 10,
                                        }}
                                    >
                                        <LabelView>
                                            <h3>Nome</h3>
                                            <p>{student?.name}</p>
                                        </LabelView>
                                        <LabelView>
                                            <h3>Nascimento</h3>
                                            <p>
                                                {age} anos,
                                                {student &&
                                                    moment(
                                                        student.birthdate
                                                    ).format(" LL")}
                                            </p>
                                        </LabelView>

                                        <LabelView>
                                            <h3>Sexo Biológico</h3>

                                            {!student?.gender ? (
                                                <p style={{ color: "red" }}>
                                                    Não Cadastrado!
                                                </p>
                                            ) : (
                                                <p>
                                                    {student?.gender.toLowerCase() ===
                                                    "male"
                                                        ? "Masculino"
                                                        : "Feminino"}
                                                </p>
                                            )}
                                        </LabelView>
                                    </div>
                                </div>
                            </Column>

                            <Column>
                                <DonutChart data={values} />
                            </Column>
                        </div>
                    </ContentView>
                    <ContentView>
                        <h1>Estatura e Peso</h1>
                        <Column style={{}}>
                            <div
                                style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: 150,
                                }}
                            >
                                <Inputfield
                                    inputProps={{
                                        placeholder: "Estatura",
                                        defaultValue: antrop.height,
                                        type: "number",
                                        max: 999,
                                        maxLength: 5,
                                        step: 0.01,
                                        style: {
                                            textAlign: "right",
                                        },
                                    }}
                                    width={120}
                                    icon={MdHeight}
                                    onChangeText={handleChangeHeight}
                                    selectOnFocus
                                    hint="Estatura (M/Cm)"
                                />
                            </div>

                            <span />

                            <div
                                style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: 150,
                                }}
                            >
                                <Inputfield
                                    inputProps={{
                                        placeholder: "Peso",
                                        defaultValue: antrop.weight,
                                        type: "number",
                                        step: 1,
                                        style: {
                                            textAlign: "right",
                                        },
                                        maxLength: 5,
                                    }}
                                    width={120}
                                    icon={MdMonitorWeight}
                                    onChangeText={handleChangeWeight}
                                    selectOnFocus
                                    hint=" Peso (Kg)"
                                />
                            </div>
                        </Column>
                    </ContentView>
                    <ContentView>
                        <h1>Circunferências</h1>
                        <Perimetry
                            protocol={antrop.content.protocol}
                            defaultPerimetry={antrop.content.perimetry}
                            onChangePerimetry={handleChangePerimetry}
                        />
                    </ContentView>
                    <ContentView>
                        <h1>Dobras Cutâneas</h1>
                        <Folds
                            defaultProtocol={antrop.content.protocol}
                            defaultValues={antrop.content.folds}
                            onChangeProtocol={handleChangeProtocol}
                            onChangeFolds={handleChangeFolds}
                        />
                    </ContentView>
                    <ContentView>
                        <h1>Diâmetro Ósseo</h1>
                        <Bones
                            onChangeBones={handleChangeBones}
                            defaultBones={antrop.content.bones}
                        />
                    </ContentView>
                </Scrollable>
            </Content>
        )
}

export default AssessmentContent
