import React, { useEffect, useState } from "react"
import { TemplatesList, Container } from "./styles"
import api from "src/rest/api"

import mostrarAlerta from "../../../components/utils/alert"
import {
    Workout,
    WorkoutType,
    WorkoutTypeString,
    _Workout,
} from "../../../modules/fithub-shared/interfaces/workout"
import { HiOutlineDocumentDuplicate } from "react-icons/hi"
import moment from "moment"
import { labels } from "../../../assets/strings/labels"
import Dialog from "src/components/dialog"
import SelectWorkoutType from "src/modules/protocols/components/select-workout-type"
import SideListView from "src/components/SideListView"
import ListInfoHeader from "src/modules/protocols/components/StudentInfoHeader"
import { WorkoutsIcon } from "src/assets/static/icons"
import WorkoutView from "src/modules/protocols/Plan/View/WorkoutView"
import WorkoutHeaderTemplate from "src/modules/protocols/Plan/View/WorkoutView/HeaderExcTemplate"
import { useDialog } from "src/contexts/dialog-context"
import { useOverlay } from "src/screens/checkout/context"
import { CenteredDiv } from "src/screens/calendar/styling/styles"
import WorkingWarn from "src/components/WorkingWarn"

// import { Container } from './styles';

const WorkoutTemplates: React.FC = () => {
    const [list, setList] = useState<_Workout[]>([])
    const [workout, setWorkout] = useState<_Workout | null>(null)

    const over = useOverlay()

    function unselect() {
        setWorkout(null)
    }

    function handleCreate() {
        over.setShow(
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    width: "100%",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <SelectWorkoutType
                    onSetType={(wt) => {
                        NewTemplate(wt)
                        over.dismiss()
                        GetList()
                    }}
                    dismiss={over.dismiss}
                />
            </div>
        )

        setWorkout(null)
    }

    useEffect(() => {
        GetList()
    }, [])

    async function GetList() {
        await api
            .post("workout-templates/list")
            .then((res) => {
                const data = res.data as _Workout[]
                data.forEach((e) => {
                    e.modified = localStorage.getItem(`workout_${e.id}`) != null
                })
                setList(data)
            })
            .catch((err) => {
                mostrarAlerta(err)
            })
    }

    function GetWorkout(e: _Workout) {
        if (e.id === workout?.id) {
            setWorkout(null)
            return
        }

        if (e.modified) {
            const lw = JSON.parse(
                localStorage.getItem(`workout_${e.id}`) as string
            ) as _Workout

            setWorkout(lw)
        } else {
            setWorkout(e)
        }
    }

    async function OnSave() {
        if (workout === null) return

        await api
            .post("/workout-templates/edit", {
                id: workout.id,
                title: workout.title,
                item: JSON.stringify(workout.item),
                obs: workout.obs,
            })
            .then(() => {
                mostrarAlerta("Salvo!")
                localStorage.removeItem(`workout_${workout.id}`)
                GetList()
            })
            .catch((err) => mostrarAlerta(err))
    }

    function onModifiedWorkout(w: Workout) {
        const workout = w as _Workout
        workout.modified = true

        mostrarAlerta(workout.modified ? "true" : "false")

        const index = list.findIndex((e) => e.id === workout.id)

        localStorage.setItem(
            `workout_${w.id}`,
            JSON.stringify(workout as Workout)
        )

        list[index] = workout
        setList(list)
        setWorkout(workout)
    }

    async function NewTemplate(type: number) {
        const nw = new Workout(type, "Novo Template")
        await api
            .post("workout-templates/new", {
                title: nw.title,
                obs: "",
                type: nw.type,
                item: JSON.stringify(nw.item),
            })
            .then((res) => {
                const nl = [...list]
                const n = res.data as _Workout

                nl.unshift(n)
                setList(nl)
                GetWorkout(n)
            })
            .catch((err) => mostrarAlerta(err))
    }

    const dialog = useDialog()

    function Delete(id: string) {
        dialog.openDialog(
            labels.delete.workoutTemplateTitle,
            labels.delete.workoutTemplateSubtitle,
            () => {
                api.post("workout-templates/delete", { id: id })
                    .then(() => {
                        const index = list.findIndex((e) => e.id === id)
                        list.splice(index, 1)
                        setWorkout(null)
                    })
                    .catch(mostrarAlerta)
            }
        )
    }

    return (
        <Container className="flex row">
            <SideListView
                list={
                    <TemplatesList>
                        <ListInfoHeader
                            create={handleCreate}
                            data={{
                                icon: WorkoutsIcon,
                                title: "Treinos Template",
                            }}
                            subtitle="TREINOS"
                        />

                        <ul style={{ paddingInline: 5 }}>
                            {list.map((element, index) => {
                                return (
                                    <li
                                        key={index}
                                        onClick={() => GetWorkout(element)}
                                        style={{
                                            backgroundColor:
                                                workout?.id === element.id
                                                    ? "#ccc"
                                                    : "#fff",
                                        }}
                                    >
                                        <div style={{ alignItems: "center" }}>
                                            <HiOutlineDocumentDuplicate
                                                size={18}
                                            />
                                            <div
                                                style={{
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <p className="title">
                                                    {element.title}
                                                    <p
                                                        style={{
                                                            color: "var(--primary)",
                                                            marginLeft: 5,
                                                        }}
                                                    >
                                                        {element.modified &&
                                                            `(MODIFICADO)`}
                                                    </p>
                                                </p>

                                                <p className="subtitle">
                                                    {WorkoutTypeString(
                                                        element.type
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ alignItems: "center" }}>
                                            <p className="date">
                                                {moment(
                                                    element.created_at
                                                ).format("DD/MM/yyyy")}
                                            </p>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </TemplatesList>
                }
                content={
                    workout ? (
                        <div
                            className="fade-in"
                            style={{
                                flexDirection: "column",
                                flex: 1,
                                padding: 5,
                                position: "relative",
                            }}
                        >
                            <WorkoutHeaderTemplate
                                workout={workout as Workout}
                                handleSave={OnSave}
                                RenameWorkout={(name) => alert(name)}
                                modified={false}
                                unsetModified={() => alert("Unset Mod")}
                                tag={`workout_${workout.id}`}
                                onRemove={() => Delete(workout.id)}
                            />

                            {workout.type === WorkoutType.weight ? (
                                <WorkoutView
                                    values={workout}
                                    setValues={onModifiedWorkout}
                                    setObs={(obs) => alert(obs)}
                                    tag={`workout_${workout.id}`}
                                />
                            ) : (
                                <CenteredDiv>
                                    <WorkingWarn />
                                </CenteredDiv>
                            )}
                        </div>
                    ) : (
                        <div
                            style={{
                                flex: 1,
                                width: "100%",
                                justifyContent: "center",
                            }}
                        >
                            <p>Selecione um Treino Template</p>
                        </div>
                    )
                }
                onGoBack={unselect}
                view={workout ? "CONTENT" : "LIST"}
            />
        </Container>
    )
}

export default WorkoutTemplates
