import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import { TAG_ACCESS, TAG_REFRESH } from "src/modules/fithub-shared/TokenManager"

import styled from "styled-components"

// import { Container } from './styles';

const Float = styled.div`
    z-index: 99;
    color: white;

    position: absolute;
    top: 10px;
    left: 10px;

    padding: 10px;

    min-width: 200px;
    min-height: 200px;

    background-color: #0008;

    border-radius: 5px;

    display: flex;

    flex-direction: column;

    pointer-events: none;

    * {
        pointer-events: none;
    }

    div {
        display: flex;
        background-color: #0008;
    }

    > ul {
        flex-direction: column;
        > li {
            text-decoration: none;
            background-color: #0008;
        }
    }
`

function cut(value: string) {
    return (
        value.substring(0, 10) +
        "..." +
        value.substring(value.length - 10, value.length)
    )
}

const TokenInspector: React.FC = () => {
    const [enable, setEnable] = useState(false)
    const [access, setAccess] = useState(Cookies.get(TAG_ACCESS))
    const [refresh, setRefresh] = useState(Cookies.get(TAG_REFRESH))

    useEffect(() => {
        setAccess(Cookies.get(TAG_ACCESS))
        setRefresh(Cookies.get(TAG_REFRESH))
    }, [enable])

    // Atualizar os tokens apenas quando o componente está ativado (enable == true)
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null

        if (enable) {
            // Cria o intervalo quando o componente é ativado
            interval = setInterval(() => {
                setAccess(Cookies.get(TAG_ACCESS))
                setRefresh(Cookies.get(TAG_REFRESH))
            }, 1000)
        }

        return () => {
            // Limpa o intervalo quando o componente é desativado
            if (interval) clearInterval(interval)
        }
    }, [enable]) // Dependência no estado "enable"

    // Listener para ativar/desativar o componente com CTRL + SHIFT + I
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Detecta se CTRL + SHIFT + I foram pressionadas
            if (event.ctrlKey && event.shiftKey && event.key === "I") {
                event.preventDefault() // Evita o comportamento padrão
                setEnable((prev) => !prev) // Alterna o estado "enable"
            }
        }

        window.addEventListener("keydown", handleKeyDown)

        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [])

    // Não renderiza o componente se "enable" for falso
    if (!enable) {
        return <React.Fragment />
    } else {
        return (
            <Float>
                <h5>Token Management</h5>
                <ul>
                    <li>ACCESS: {cut(access ?? "")}</li>
                    <li>REFRESH: {cut(refresh ?? "")}</li>
                </ul>
            </Float>
        )
    }
}

export default TokenInspector
