import React from "react"
import { Card } from ".."
import styled from "styled-components"

import { NotificationData } from "src/modules/fithub-shared/notifications/index.types"
import api from "src/rest/api"
import { LineWave } from "react-loader-spinner"

import NotFeedback from "./NotContent/feedback"
import { CenteredDiv } from "src/screens/calendar/styling/styles"
import { useQuery } from "react-query"
import { BiBell } from "react-icons/bi"
import { PiBellFill } from "react-icons/pi"

// import { Container } from './styles';

const Title = styled.h4`
    width: fit-content;
    color: var(--preto);
    font-weight: 600;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    padding-right: 5px;
`

const Header = styled.div`
    width: 100%;
    justify-content: center;
    align-items: center;
`

const ItemBackground = styled.li`
    position: relative;
    flex-direction: row; /* Mudar para vertical */
    padding-block: 5px;
    padding-bottom: 8px;
    cursor: pointer;

    min-height: 50px;

    align-items: center;

    :not(:last-child) {
        margin-bottom: 5px;
        border-bottom: 1px solid #ddd;
    }
`

const Listing = styled.ul`
    flex-direction: column; /* Garantir que os itens estejam em coluna */
    max-height: 400px; /* Definir um limite de altura, caso necessário */
    overflow-y: scroll; /* Permitir scroll se o conteúdo exceder a altura */
`

export const Paragraph = styled.p`
    text-align: start;
    width: 100%;
    white-space: normal; /* Permitir quebra de linha */
    word-wrap: break-word; /* Quebra de palavras longas */
    margin: 0;
    font-size: 10px;

    a {
        width: fit-content;
        background-color: red;
    }

    padding-top: 10px;
    color: #888;
`

const NotificationsCard: React.FC = () => {
    const content = useQuery("Notifications-card", () =>
        api
            .post<NotificationData[]>("/dashboard/notifications")
            .then((r) => r.data)
            .catch(() => [])
    )

    const NotificationContent = (not: NotificationData) => {
        switch (not.type) {
            default:
                return <p></p>
            case "FEEDBACK":
                return <NotFeedback e={not} />
        }
    }

    return (
        <Card style={{ flexDirection: "column" }}>
            <Header>
                <Title>Notificações</Title>
            </Header>

            {content.data && content.data.length > 0 ? (
                <Listing className="flex column">
                    {content.data.map((e, i) => (
                        <ItemBackground key={"not_" + i}>
                            {NotificationContent(e)}
                        </ItemBackground>
                    ))}
                </Listing>
            ) : (
                <CenteredDiv style={{ flexDirection: "column" }}>
                    {content.isLoading ? (
                        <LineWave height={60} width={60} color="#000" />
                    ) : (
                        <div style={{ flexDirection: "column", color: "#777" }}>
                            <PiBellFill />
                            <h5>Sem Notificações!</h5>
                        </div>
                    )}
                </CenteredDiv>
            )}
        </Card>
    )
}

export default NotificationsCard
