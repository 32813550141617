import React, { useRef, useState } from "react"
import { MdAdd } from "react-icons/md"

import { Buttons, CreateButton, ExerciseListing, Wrapper } from "./styles"
import { nanoid } from "nanoid"

import md5 from "md5"

import {
    restrictToVerticalAxis,
    restrictToWindowEdges,
} from "@dnd-kit/modifiers"

import {
    DndContext,
    DragEndEvent,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import {
    SortableContext,
    verticalListSortingStrategy,
    arrayMove,
} from "@dnd-kit/sortable"
import MutableIcon from "src/components/mutable-icon"
import { WeightExercise } from "src/modules/fithub-shared/interfaces/exercise"
import { Weight } from "src/modules/fithub-shared/interfaces/workout"
import TemplatesBrowser from "src/modules/protocols/components/templates-browser"
import WeightExerciseComponent from "src/modules/protocols/Plan/View/WorkoutView/weight/Item"

import { useDialog } from "src/contexts/dialog-context"
import { SmartSensor } from "src/assets/functions/dnd"
import { ObsWindow } from "../Header/styles"

interface WeightWorkoutProps {
    content: Weight
    obs: string

    onChange: (mod: Weight) => void
    onChangeObs: (obs: string) => void
}

const WeightWorkout: React.FC<WeightWorkoutProps> = (props) => {
    const [content] = useState({ ...props.content })
    const [exercises, setExercises] = useState(content.exercises)

    const dialog = useDialog()

    const [addExc, setAddExc] = useState(false)
    const [replace, setReplace] = useState<WeightExercise>()

    const dummy = useRef<HTMLDivElement>(null)

    function updateContent(data: WeightExercise[]) {
        const c = { ...content, exercises: data }
        props.onChange(c)
    }

    function handleChangeExercise(data: WeightExercise, index: number) {
        const excs = [...exercises]
        excs[index] = data
        updateContent(excs)
        setExercises(excs)
    }

    function AddExercise(name: string, id: string) {
        const newExc = new WeightExercise(name, id, md5(nanoid() + Date.now()))

        try {
            if (!replace) {
                const updatedExercises = [...exercises, newExc] // Create a new array reference
                updateContent(updatedExercises)
                setExercises(updatedExercises) // Update the state with the new array reference
            } else {
                const updatedExercises = [...exercises]
                const i = exercises.indexOf(replace)
                newExc.values = updatedExercises[i].values
                updatedExercises[i] = newExc
                updateContent(updatedExercises)
                setExercises(updatedExercises) // Update the state with the new array reference
                setReplace(undefined)
            }
        } catch (e) {
            console.error(e)

            const AppendAlert = (strs: any[]) => {
                alert(strs.join("\n\n"))
            }

            AppendAlert([
                e,
                JSON.stringify(exercises[0]),
                JSON.stringify(newExc),
                JSON.stringify(replace),
            ])
        }
    }

    function ReplaceExercise(w: WeightExercise) {
        setReplace(w)
        setAddExc(true)
    }
    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event

        if (active.id !== over?.id) {
            const oldIndex = exercises.findIndex((it) => it.id === active.id)
            const newIndex = exercises.findIndex((it) => it.id === over?.id)

            const newExercises = arrayMove([...exercises], oldIndex, newIndex) // Create a new array reference
            setExercises(newExercises)
            updateContent(newExercises)
        }
    }

    function RemoveExercise(exc: WeightExercise) {
        dialog.openDialog(
            "Remover Exercício",
            "Gostaria de remover este exercício do treino?",
            () => {
                const newExercises = [...exercises].filter(
                    (e) => e.id !== exc.id
                )
                setExercises(newExercises)
                updateContent(newExercises)
            }
        )
    }

    const sensors = useSensors(
        useSensor(SmartSensor.sensor, SmartSensor.activation)
    )

    return (
        <Wrapper style={{ paddingInline: 10 }}>
            <ExerciseListing>
                {exercises.length === 0 ? (
                    <div style={{ padding: 10 }}>
                        <p
                            style={{
                                backgroundColor: "#0002",
                                padding: 5,
                                borderRadius: 5,
                            }}
                        >
                            Você ainda não adicionou nenhum exercício!
                        </p>
                    </div>
                ) : (
                    <DndContext
                        autoScroll={{ layoutShiftCompensation: false }}
                        collisionDetection={closestCenter}
                        sensors={sensors}
                        onDragEnd={handleDragEnd}
                        modifiers={[
                            restrictToVerticalAxis,
                            restrictToWindowEdges,
                        ]}
                    >
                        <SortableContext
                            items={exercises}
                            strategy={verticalListSortingStrategy}
                        >
                            {exercises.map((exercise, index) => (
                                <WeightExerciseComponent
                                    key={`${exercise.id}${index}` + "_key"}
                                    id={exercise.id}
                                    index={index}
                                    onChange={(d) =>
                                        handleChangeExercise(d, index)
                                    }
                                    onReplace={ReplaceExercise}
                                    def={exercise}
                                    onDelete={() => RemoveExercise(exercise)}
                                />
                            ))}
                        </SortableContext>
                    </DndContext>
                )}

                <Buttons>
                    <span />
                    <div>
                        <div
                            style={{
                                flexDirection: "column",
                                alignItems: "center",
                                marginInline: 25,
                            }}
                        >
                            <CreateButton
                                tabIndex={2}
                                onClick={() => setAddExc(true)}
                            >
                                <MutableIcon size={30} icon={MdAdd} />
                            </CreateButton>
                        </div>
                    </div>

                    <span />
                </Buttons>

                <p
                    style={{
                        marginTop: 0,
                        fontWeight: "bold",
                        color: "#444",
                        paddingBottom: 10,
                    }}
                >
                    Adicionar Exercício
                </p>

                <ObsWindow>
                    <p>Observações do Treino:</p>
                    <textarea
                        defaultValue={props.obs}
                        onChange={(e) => props.onChangeObs(e.target.value)}
                        placeholder="Digite aqui as observações gerais do treino..."
                    />
                </ObsWindow>

                <div ref={dummy} />
            </ExerciseListing>

            <TemplatesBrowser
                active={addExc}
                type="Exercise"
                headerTitle={`${replace ? "Substituir" : "Importar"} Exercício`}
                onSelect={(e, i) => AddExercise(e, i)}
                onDismis={setAddExc}
            />
        </Wrapper>
    )
}

export default WeightWorkout
