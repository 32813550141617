import styled from "styled-components"

export const ExerciseInfo = styled.div`
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 10px;

    width: 100%;
    max-width: 1000px;

    align-items: start;
    justify-content: start;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`

export const NameHeader = styled.div`
    width: 100%;
    flex-direction: row;
    font-size: 32px;

    align-items: center;
`

export const EditableLabel = styled.input<{ disabled?: boolean }>`
    box-shadow: none;
    flex: 1;

    ${(p) => !p.disabled && "text-decoration: underline lightgrey;"}
`

export const Section = styled.div<{ disabled?: boolean }>`
    font-size: 16px;

    ${(p) => p.disabled && "font-style: italic;"}
    ${(p) => p.disabled && "color: #888;"}

    padding: 5px;
    border-radius: 5px;

    > input {
        text-align: right;
        font-size: 16px;
        background-color: unset;
    }

    align-items: center;
    width: 100%;
    justify-content: space-between;

    :nth-child(odd) {
        background-color: #eee;
    }
`

export const Footer = styled.div`
    width: 100%;
    justify-content: end;
    padding-block: 10px;

    button {
        background-color: var(--primary);
        padding: 8px;
        border-radius: 8px;
        align-items: center;

        color: white;

        cursor: pointer;

        :active {
            opacity: 0.75;
        }
    }
`
