import React, { useRef } from "react"
import { WorkoutType } from "../../../fithub-shared/interfaces/workout"
import { CenteredContent, WorkoutTypeButton, Wrapper } from "./styles"
import { useOnOutClick } from "src/components/hooks"

// import { Container } from './styles';

interface WorkoutTypeProps {
    onSetType: (t: WorkoutType) => void
    dismiss?: () => void
}

const SelectWorkoutType: React.FC<WorkoutTypeProps> = (props) => {
    const ref = useRef(null)

    if (props.dismiss) useOnOutClick(ref, props.dismiss)

    return (
        <Wrapper>
            <CenteredContent ref={ref} className="zoom-in">
                <WorkoutTypeButton
                    onClick={() => props.onSetType(WorkoutType.weight)}
                >
                    <p>Musculação</p>
                </WorkoutTypeButton>
                <span />
                <WorkoutTypeButton
                    onClick={() => props.onSetType(WorkoutType.aerobic)}
                >
                    <p>Aeróbico</p>
                </WorkoutTypeButton>
                <span />
                <WorkoutTypeButton
                    onClick={() => props.onSetType(WorkoutType.crossfit)}
                >
                    <p>Crossfit</p>
                </WorkoutTypeButton>
                <WorkoutTypeButton
                    onClick={() => props.onSetType(WorkoutType.heating)}
                >
                    <p>Aquecimento</p>
                </WorkoutTypeButton>
            </CenteredContent>
        </Wrapper>
    )
}

export default SelectWorkoutType
