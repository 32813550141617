import React from "react"
import { ExTemplateLI } from "src/modules/fithub-shared/interfaces/templates"

// import { Container } from './styles';

export const FilterAndSortExc = (list: ExTemplateLI[], search: string) => {
    return search.length > 0
        ? list
              .filter((e) =>
                  e.name.toUpperCase().includes(search.toUpperCase())
              )
              .sort((a, b) => {
                  const key = search.toLowerCase()
                  const isGoodMatchA = a.name.toLowerCase().startsWith(key)
                  const isGoodMatchB = b.name.toLowerCase().startsWith(key)

                  if (isGoodMatchA !== isGoodMatchB) {
                      // XOR
                      return isGoodMatchA ? -1 : 1
                  }

                  return a.name.localeCompare(b.name)
              })
              .slice(0, 50)
        : list
}

const ExerciseTemplates: React.FC = () => {
    return (
        <div
            className="centered"
            style={{
                display: "flex",
                width: "100%",
            }}
        ></div>
    )
}

export default ExerciseTemplates
