import moment from "moment"
import React, { useState } from "react"
import { BiExport, BiCopy, BiCloudUpload } from "react-icons/bi"
import { MdEdit, MdDelete } from "react-icons/md"
import Inputfield from "../../../../../components/inputfield"
import {
    Content,
    ConfigList,
    ConfigItem,
    Actions,
    Tooltip,
    ConfigSubtitle,
} from "./styles"
import { ProtocolType } from "../../../../fithub-shared/interfaces/protocol"
import { ToggleArea } from "../../../components/protocol-list-item/styles"
import Toggle from "react-toggle"

import { usePlan } from "src/modules/protocols/context"
import DateTimePicker from "src/components/DateTimePicker"

interface SetupProps {
    onChangeTitle: (t: string) => void
    onChangeObs: (t: string) => void
    onDelete: (id: string) => void
    onDuplicate: (id: string) => void
    onSaveTemplate: (id: string) => void

    onExport: (id: string) => void
}

const convertDate = (value: Date | string | null | undefined) => {
    if (typeof value === "string") value = new Date(value)

    return value ?? null
}

function diasRestantes(dataFutura: Date): string {
    const dataAtual = moment()
    const dataFuturo = moment(dataFutura)
    const diferencaEmDias = dataFuturo.diff(dataAtual, "days")

    if (diferencaEmDias === 0) {
        const mins = dataFuturo.diff(dataAtual, "minutes")
        if (mins > 60) {
            const horas = dataFuturo.diff(dataAtual, "hours")
            return `${horas} hora${horas === 1 ? "s" : "s"}`
        } else {
            return mins <= 0
                ? "Em Breve"
                : `${mins} minuto${mins > 1 ? "s" : ""}`
        }
    } else {
        return `${diferencaEmDias} dia${diferencaEmDias > 1 ? "s" : ""}`
    }
}

const ProtocolSetup: React.FC<SetupProps> = (props) => {
    const { reloadList, plan } = usePlan()

    const [current, setCurrent] = useState(plan.selected)

    const [enable, setEnable] = useState(convertDate(plan.selected?.enable))

    const def_active = plan.selected?.active

    const [active, setActive] = useState(def_active)

    const [tooltip, setTooltip] = useState({ x: 0, y: 0, active: "" })

    function onMouseOver(e: React.MouseEvent<HTMLButtonElement>) {
        setTooltip({
            x: e.currentTarget.offsetLeft + e.currentTarget.offsetWidth / 2,
            y: e.currentTarget.offsetTop,
            active: e.currentTarget.getAttribute("aria-label") as string,
        })
    }

    function onMouseOut() {
        setTooltip({ x: 0, y: 0, active: "" })
    }

    function handleToggle() {
        const status = !active
        if (current === null) return
        const nplan = { ...plan.selected } as ProtocolType
        nplan.active = status
        setActive(status)
        plan.update(nplan)
    }

    function handleDelete() {
        props.onDelete(plan.selected?.id ?? "")
    }

    function handleExport() {
        props.onExport(plan.selected?.id ?? "")
    }

    function handleDuplicate() {
        props.onDuplicate(plan.selected?.id ?? "")
    }

    function handleSaveTemplate() {
        props.onSaveTemplate(plan.selected?.id ?? "")
    }

    function onSetEnable(date: Date | null) {
        const nplan = { ...plan.selected } as ProtocolType

        setEnable(date)
        nplan.enable = date

        if (active) {
            nplan.active = false
            setActive(false)
        }

        if (nplan.disable && nplan.enable && nplan.disable < nplan.enable) {
            nplan.disable = null
        }

        plan.update(nplan)
    }

    function onSetDisable(date: Date | null) {
        const nplan = { ...plan.selected } as ProtocolType

        if (date !== null && nplan.enable) {
            nplan.disable = date < nplan.enable ? nplan.enable : date
        } else {
            nplan.disable = date
        }

        plan.update(nplan)
    }

    return (
        <Content id="centered">
            {tooltip.active !== "" && (
                <Tooltip className={"fade-in"} x={tooltip.x} y={tooltip.y}>
                    {tooltip.active}
                </Tooltip>
            )}
            <ConfigList>
                <ConfigItem style={{ flexDirection: "column" }}>
                    <div style={{ flex: 1, width: "100%" }}>
                        <Inputfield
                            inputProps={{
                                defaultValue: plan.selected?.title,
                            }}
                            icon={MdEdit}
                            onEndText={props.onChangeTitle}
                            style={{ fontSize: 16, flex: 1, marginRight: 10 }}
                        />
                        <ToggleArea
                            title={`${
                                active ? "Desativar" : "Ativar"
                            } visibilidade do plano para o aluno`}
                        >
                            <Toggle
                                checked={active}
                                onChange={handleToggle}
                                icons={false}
                            />
                        </ToggleArea>
                    </div>
                    <div style={{ width: "100%", alignItems: "center" }}>
                        <h5 style={{ paddingTop: 5, opacity: 0.7 }}>
                            Criado em
                            {moment(plan.selected?.created_at).format(
                                " LL [às] HH:mm"
                            )}
                        </h5>
                    </div>
                </ConfigItem>

                <ConfigItem style={{ opacity: active ? 0.5 : 1 }}>
                    <div style={{ flexDirection: "column" }}>
                        <h4>Data de Ativação: </h4>

                        <ConfigSubtitle>
                            {!plan.selected?.enable || active ? (
                                "Defina uma data para ATIVAR."
                            ) : (
                                <>
                                    Protocolo ativará em
                                    <strong>
                                        {diasRestantes(plan.selected.enable)}
                                    </strong>
                                </>
                            )}
                        </ConfigSubtitle>
                    </div>

                    <DateTimePicker
                        minDate={new Date()}
                        defaultValue={enable}
                        onChange={onSetEnable}
                    />
                </ConfigItem>
                <ConfigItem>
                    <div style={{ flexDirection: "column" }}>
                        <h4>Data de Desativação: </h4>
                        <ConfigSubtitle>
                            {!plan.selected?.disable || !active ? (
                                "Defina uma data para DESATIVAR."
                            ) : (
                                <>
                                    Protocolo desativará em
                                    <strong>
                                        {diasRestantes(plan.selected.disable)}
                                    </strong>
                                </>
                            )}
                        </ConfigSubtitle>
                    </div>
                    <DateTimePicker
                        minDate={enable ?? new Date()}
                        defaultValue={convertDate(plan.selected?.disable)}
                        onChange={onSetDisable}
                    />
                </ConfigItem>

                <ConfigItem>
                    <h4>Quantidade de Treinos:</h4>
                    <ConfigSubtitle>
                        {plan.selected?.workouts.length}
                    </ConfigSubtitle>
                </ConfigItem>

                <ConfigItem>
                    <Actions color="red">
                        <button
                            className="delete"
                            aria-label={"Deletar Plano"}
                            onMouseEnter={onMouseOver}
                            onMouseLeave={onMouseOut}
                            onClick={handleDelete}
                        >
                            <MdDelete size={20} />
                        </button>
                    </Actions>
                    <Actions color="#444">
                        <button
                            className="action"
                            aria-label={"Salvar como Template"}
                            onMouseEnter={onMouseOver}
                            onMouseLeave={onMouseOut}
                            onClick={handleSaveTemplate}
                        >
                            <BiCloudUpload size={20} />
                        </button>
                        <span />
                        <button
                            className="action"
                            aria-label={"Exportar para outro Aluno"}
                            onMouseEnter={onMouseOver}
                            onMouseLeave={onMouseOut}
                            onClick={handleExport}
                        >
                            <BiExport size={20} />
                        </button>
                        <span />
                        <button
                            className="action"
                            aria-label={"Duplicar este Plano"}
                            onMouseEnter={onMouseOver}
                            onMouseLeave={onMouseOut}
                            onClick={handleDuplicate}
                        >
                            <BiCopy size={20} />
                        </button>
                    </Actions>
                </ConfigItem>
            </ConfigList>
            <ConfigList style={{ marginTop: 5 }}>
                <div>
                    <p style={{ fontWeight: "bold", fontSize: "small" }}>
                        OBSERVAÇÕES DO PROTOCOLO
                    </p>
                </div>
                <textarea
                    defaultValue={plan.selected?.obs}
                    onBlur={(e) => props.onChangeObs(e.target.value)}
                    maxLength={300}
                    style={{
                        padding: 5,
                        resize: "none",
                        minHeight: 80,
                    }}
                ></textarea>
            </ConfigList>
        </Content>
    )
}

export default ProtocolSetup
