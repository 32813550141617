import styled from "styled-components"

interface SmallDetect {
    small?: boolean
}

export const Background = styled.div<SmallDetect>`
    display: flex;

    * {
        display: flex;
    }

    flex-direction: row;

    align-items: center;

    width: 100%;
    justify-content: space-between;

    height: 60px;

    @media (max-width: 768px) {
        display: none;
        #hideMobile {
            display: none;
        }
    }
`

export const Avatar = styled.img<SmallDetect>`
    height: ${(p) => (p.small ? "40px" : "50px")};
    width: ${(p) => (p.small ? "40px" : "50px")};
    aspect-ratio: 1;
    margin-left: 10px;
    border-radius: 50px;

    flex-shrink: 0;

    font-size: 0px;
`

export const Badge = styled.div`
    height: 4px;
    width: 4px;
    position: fixed;
    background-color: #f00;
    border-radius: 15px;
`

export const Logo = styled.img`
    height: 60px;
`

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20%;
    min-width: 80px;
    text-overflow: clip;
    padding-inline: 5px;
`

export const Labels = styled.div`
    display: flex;

    flex-direction: column;
    max-height: 80px;

    * {
        padding: 0px;
        margin: 0px;
        margin-left: 10px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    h5 {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
    }

    p {
        width: 100%;
        font-weight: 800;
        font-size: 12px;
    }

    @media (max-width: 768px) {
        h5 {
            font-size: 12px;
        }
        p {
            font-size: 10px;
        }
    }
`
