import axios from "axios"

import config from "./config.api"
import TokenManager from "src/modules/fithub-shared/TokenManager"
import { QueryClient, QueryClientConfig } from "react-query"

const api = axios.create(config)

export const rqueryclient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
} as QueryClientConfig)

export const altToken = new TokenManager(api, rqueryclient)

export default api
