import React, { useEffect, useState } from "react"
import styled from "styled-components"
import InfoCard from "./InfoCard"
import { BiCalendar } from "react-icons/bi"
import { PiUsersThreeBold } from "react-icons/pi"
import { TbZoomMoney } from "react-icons/tb"
import api from "src/rest/api"
import mostrarAlerta from "src/components/utils/alert"
import { useStudent } from "src/contexts/student"
import { useNavigate } from "react-router-dom"
import ToolsCard from "./ToolsCard"
import ChangeLogCard from "./ChangelogCard"
import StudentsModal from "src/components/StudentsModal"
import NotificationsCard from "./NotificationsCard"

const DashboardGridContainer = styled.div`
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    height: 100vh; /* Ajuste a altura conforme necessário */

    max-width: 1366px;
    align-self: center;

    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        padding: 0px; /* 100% de largura em telas menores */
    }
`

const GridItem = styled.div<{ flex?: number }>`
    flex: ${(props) => props.flex || 1};
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FirstRowItem = styled(GridItem)`
    flex: 1 1 33.33%;
    min-width: 300px;
    min-height: 100px;
    max-height: 200px;
    height: 20%;
`

const SecondRowItem = styled(GridItem)<{ flex: number }>`
    flex: ${(props) => props.flex || 1};
    min-width: 300px;
    height: 80%;

    max-height: 400px;

    @media (max-width: 768px) {
        flex: 1; /* 100% de largura em telas menores */
    }
`

export const Card = styled.div`
    flex: 1;
    height: 100%;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    border: 2px solid #ddd;
`

export const CardButton = styled.button`
    flex: 1;
    height: 100%;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    border: 2px solid #ddd;

    cursor: pointer;

    transition: border 0.1s;

    :active {
        border: 2px solid #888;
    }
`

const DashboardGrid = () => {
    const [alunos, setAlunos] = useState(false)

    const [alerts, setAlerts] = useState({
        students: 0,
        schedules: 0,
    })

    function GetInfo() {
        api.post("/dashboard/getinfo")
            .then((res) => setAlerts(res.data))
            .catch((err) => mostrarAlerta(err))
    }

    useEffect(() => {
        student.onSetStudent(undefined)
        GetInfo()
    }, [])

    const student = useStudent()

    const navigate = useNavigate()

    return (
        <DashboardGridContainer className="flex row">
            <StudentsModal active={alunos} setActive={setAlunos} />
            {/* Primeira linha */}
            <FirstRowItem>
                <InfoCard
                    Icon={PiUsersThreeBold}
                    amount={alerts.students}
                    subtitle={"Alunos"}
                    onClick={() => setAlunos(true)}
                />
            </FirstRowItem>
            <FirstRowItem>
                <InfoCard
                    Icon={BiCalendar}
                    amount={alerts.schedules}
                    subtitle={"Atividades Hoje"}
                    onClick={() => navigate("calendar")}
                />
            </FirstRowItem>
            <FirstRowItem>
                <InfoCard
                    Icon={TbZoomMoney}
                    amount={0}
                    subtitle={"Financeiro"}
                    onClick={() => navigate("/checkout")}
                />
            </FirstRowItem>

            {/* Segunda linha */}

            <SecondRowItem flex={1 / 3}>
                <ToolsCard />
            </SecondRowItem>
            <SecondRowItem flex={1 / 3}>
                <NotificationsCard />
            </SecondRowItem>
            <SecondRowItem flex={1.25 / 3}>
                <ChangeLogCard />
            </SecondRowItem>
        </DashboardGridContainer>
    )
}

export default DashboardGrid
