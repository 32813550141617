import React, { useEffect, useState } from "react"

import api from "src/rest/api"
import { useStudent } from "src/contexts/student"
import SideListView from "src/components/SideListView"
import { Item, List } from "./styles"
import moment from "moment"
import ListInfoHeader from "src/modules/protocols/components/StudentInfoHeader"
import { BiChevronRight } from "react-icons/bi"
import ProgressionView from "./Components/ProgressionView"
import { GetParameter } from "src/assets/functions"

export interface Progression {
    plan: string
    started: string
    title: string
}

export interface ExerciseData {
    feedback: string
    data: Array<{ reps: number; weight: number }>
    exec_at: string // ISO 8601 date format
}

const ChargeProgression: React.FC = () => {
    const defplan = GetParameter("plan")
    const highlight = GetParameter("exc")

    const [history, setHistory] = useState<Progression[]>([])
    const [selected, setSelected] = useState(defplan)

    const student = useStudent()

    useEffect(() => {
        api.post<Progression[]>("/charge/history/list", {
            userid: student.student?.id,
        }).then((res) => {
            setHistory(res.data)
        })
    }, [])

    return (
        <>
            <SideListView
                list={
                    <div className="flex column" style={{ width: "100%" }}>
                        <ListInfoHeader
                            subtitle="PROGRESSÃO DE CARGA"
                            data={undefined}
                        />
                        <List>
                            {history.map((e, i) => (
                                <Item
                                    key={i + "_prog"}
                                    data-id={e.plan}
                                    onClick={() => setSelected(e.plan)}
                                >
                                    <div>
                                        <h5>{e.title}</h5>
                                        <span style={{ margin: 5 }} />
                                        <h6>
                                            {moment(e.started).format("LLL")}
                                        </h6>
                                    </div>
                                    <BiChevronRight />
                                </Item>
                            ))}
                        </List>
                    </div>
                }
                content={
                    <div
                        className="flex column"
                        style={{ width: "100%", padding: 10 }}
                    >
                        <div style={{ paddingBottom: 20 }}>
                            <h2>
                                {
                                    history.find((e) => e.plan === selected)
                                        ?.title
                                }
                            </h2>
                        </div>
                        {selected && (
                            <ProgressionView
                                planId={selected}
                                highlight={highlight}
                            />
                        )}
                    </div>
                }
                view={"LIST"}
            />
        </>
    )
}

export default ChargeProgression
