import React from "react"
import ReactDOM from "react-dom/client"

import App from "./App"
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter } from "react-router-dom"
import { AuthProvider } from "./contexts/auth"
import GlobalStyles from "./assets/GlobalStyles"

import "moment/locale/pt-br"
import "apexcharts/dist/apexcharts.css"
import { rqueryclient } from "./rest/api"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
    <BrowserRouter>
        <React.StrictMode>
            <QueryClientProvider client={rqueryclient}>
                <App />
            </QueryClientProvider>
        </React.StrictMode>
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
