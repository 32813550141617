import React, { useState } from "react"
import { BiFingerprint, BiMobile } from "react-icons/bi"
import { MdPerson, MdEmail, MdPersonAdd } from "react-icons/md"
import Inputfield from "../../../components/inputfield"
import { AddForm, CreateButton, ListHeader } from "../styles"
import {
    identityMask,
    phoneMask,
} from "../../../modules/fithub-shared/functions/functions"
import mostrarAlerta from "../../../components/utils/alert"
import { RiInstagramFill } from "react-icons/ri"
import api from "src/rest/api"
import { Student } from "../../student-info"
import MaskInput from "src/components/inputfield/masked-input"

import Step from "./Step"
import { HiChevronLeft } from "react-icons/hi"
import MutableIcon from "src/components/mutable-icon"
import { ValidateEmail } from "src/assets/functions"
import { useDialog } from "src/contexts/dialog-context"
import InputfieldMask from "src/components/inputfield/inputfieldMask"
import InputPhone from "src/components/inputfield/InputPhone"

export interface FoundUser {
    id: string
    name: string
    phone: string
}

const CreateForm: React.FC<{
    onGoBack(): void
    onAdd: (s: Student) => void
}> = (props) => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        birthdate: "",
        gender: "Male",
        phone: "",
        identity: "",
        username: "",
    })

    //optionals

    const [found, setFound] = useState<FoundUser | undefined>(undefined)

    const [validEmail, setValidEmail] = useState(false)

    async function LocateUser(s: string) {
        setFound(undefined)

        if (s.length < 5 && ValidateEmail(s)) return
        await api
            .post("/students/find", { email: s })
            .then((res) => {
                if (res.status === 201) setFound(res.data as FoundUser)
            })
            .catch(() => setFound(undefined))
    }

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = event.target

        if (name === "email") {
            LocateUser(value)
            setValidEmail(ValidateEmail(value))
        }

        setForm({
            ...form,
            [name]: value,
        })
    }

    const handlePhone = (e: string) => {
        setForm({
            ...form,
            phone: e,
        })
    }

    const handleGender = (e: React.ChangeEvent<HTMLSelectElement>) => {
        mostrarAlerta(e.target.value)

        setForm({
            ...form,
            gender: e.target.value,
        })
    }

    async function Submit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        if (found) {
            api.post("/register/link", {
                id: found?.id,
            })
                .then((res) => {
                    props.onAdd(res.data as Student)
                    mostrarAlerta("Aluno Vinculado!")
                })
                .catch((err) => {
                    alert(err)
                    mostrarAlerta("Este aluno já está vinculado!")
                })
        } else
            await api
                .post("/register/", {
                    ...form,
                    birthdate: form.birthdate,
                })
                .then((res) => {
                    if (res.status === 201) {
                        props.onAdd(res.data as Student)
                        mostrarAlerta(`Aluno(a) Cadastrado!`)
                    } else {
                        mostrarAlerta(`Aluno(a) Cadastrado!`)
                    }
                })
                .catch((err) => alert(err))
    }

    const diag = useDialog()

    function UnsavedDialog() {
        diag.openDialog(
            "Cancelar Cadastro",
            "O Cadastro atual ainda não foi salvo! Gostaria de cancelar mesmo assim?",
            props.onGoBack
        )
    }

    return (
        <>
            <ListHeader style={{ paddingBlock: 10 }}>
                <MutableIcon
                    icon={HiChevronLeft}
                    size={40}
                    onClick={UnsavedDialog}
                />
            </ListHeader>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 20,
                    justifyContent: "space-between",
                }}
                className="flex"
            >
                <AddForm onSubmit={Submit}>
                    <p
                        style={{
                            padding: 10,
                            fontSize: 14,
                        }}
                    >
                        DADOS CADASTRAIS:
                    </p>
                    <div
                        style={{
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Inputfield
                            error={true}
                            icon={MdEmail}
                            width={"60%"}
                            hint="Endereço de Email"
                            inputProps={{
                                name: "email",
                                tabIndex: 1,
                                onChange: handleInputChange,
                                placeholder: "aluno@email.com",
                            }}
                        />
                    </div>
                    <span />
                    <Step active={validEmail} found={found}>
                        <>
                            <div>
                                <Inputfield
                                    inputProps={{
                                        name: "name",
                                        onChange: handleInputChange,
                                        tabIndex: 2,
                                        placeholder: "Nome",
                                    }}
                                    hint="Nome do Aluno"
                                    minLength={3}
                                    icon={MdPerson}
                                    style={{ flex: 1 }}
                                />

                                <span />

                                <div style={{ alignItems: "center" }}>
                                    <Inputfield
                                        inputProps={{
                                            type: "date",
                                            name: "birthdate",
                                            onChange: handleInputChange,
                                            tabIndex: 3,
                                            placeholder: "Nascimento",
                                            max: new Date()
                                                .toISOString()
                                                .split("T")[0],
                                        }}
                                        hint="Nascimento:"
                                    />
                                </div>
                            </div>
                            <span />
                            <div>
                                <select
                                    style={{ width: "22%", height: 35 }}
                                    tabIndex={4}
                                    onChange={handleGender}
                                >
                                    <option disabled>Sexo Biológico</option>
                                    <option value={"Male"}>♂️ Masculino</option>
                                    <option value={"Female"}>
                                        ♀️ Feminino
                                    </option>
                                </select>
                                <span />
                                <InputPhone
                                    width={"44%"}
                                    inputProps={{
                                        name: "phone",
                                        tabIndex: 5,
                                        onChange: handleInputChange,
                                    }}
                                    icon={BiMobile}
                                    onChange={handlePhone}
                                />
                                <span />
                                <InputfieldMask
                                    width={"44%"}
                                    inputProps={{
                                        name: "identity",
                                        tabIndex: 6,
                                        placeholder: "CPF ou Identificação",
                                        onChange: handleInputChange,
                                    }}
                                    icon={BiFingerprint}
                                    mask={{
                                        pattern: "### ### ### ### ###",
                                        allow: ["n*"],
                                    }}
                                />
                            </div>

                            <span />
                            <p
                                style={{
                                    borderTop: "1px solid #ccc",
                                    padding: 10,
                                    fontSize: 14,
                                }}
                            >
                                OPCIONAL:
                            </p>
                            <div>
                                <Inputfield
                                    icon={RiInstagramFill}
                                    width={"70%"}
                                    inputProps={{
                                        name: "username",
                                        onChange: handleInputChange,
                                        tabIndex: 7,
                                        placeholder: "Instagram",
                                    }}
                                />
                            </div>
                        </>
                    </Step>
                    <div style={{ flexDirection: "row-reverse" }}>
                        <CreateButton type="submit">
                            <MdPersonAdd />
                            <p>{found ? "VINCULAR" : "CADASTRAR"} ALUNO</p>
                        </CreateButton>
                    </div>
                </AddForm>
            </div>
        </>
    )
}

export default CreateForm
