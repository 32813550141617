import React, { useEffect, useState } from "react"
import { usePlan } from "src/modules/protocols/context"
import { List, Tab } from "./styles"
import { Workout } from "src/modules/fithub-shared/interfaces/workout"
import { TbFileImport, TbFilePlus } from "react-icons/tb"
import TemplatesBrowser from "src/modules/protocols/components/templates-browser"
import api from "src/rest/api"
import md5 from "md5"
import { nanoid } from "nanoid"
import mostrarAlerta from "src/components/utils/alert"

import {
    SortableContext,
    arrayMove,
    horizontalListSortingStrategy,
} from "@dnd-kit/sortable"
import {
    DndContext,
    DragEndEvent,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core"

import { useDialog } from "src/contexts/dialog-context"
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers"
import DraggableTab from "./DraggableTab"
import { SmartSensor } from "src/assets/functions/dnd"
import { Gentag } from "src/assets/functions/cache"

const Tabs: React.FC<{ handleCreate: () => void; create: boolean }> = (
    props
) => {
    const { plan, workout } = usePlan()

    const getTag = (wid: string) => Gentag([plan.selected?.id, wid])

    const [localWorkouts, setLocalWorkouts] = useState(
        plan.selected?.workouts ?? []
    )

    useEffect(() => {
        setLocalWorkouts(plan.selected?.workouts ?? [])
    }, [plan.selected])

    const dialog = useDialog()

    const [importTemplate, setImport] = useState(false)

    function handleSelect(e: Workout) {
        if (props.create) props.handleCreate()
        if (workout.selected === e) {
            workout.unselect()
        } else {
            workout.unselect()
            workout.setSelected(e)
        }
        saveChanges()
    }
    function handleCreate() {
        saveChanges()
        props.handleCreate()
    }

    function Delete(del: Workout) {
        dialog.openDialog(
            "Deletar Treino",
            "Gostaria de deletar este treino?",
            () => {
                if (!plan.selected) return
                const nplan = { ...plan.selected }
                const f = nplan.workouts.findIndex((e) => e === del)
                f !== -1 && nplan.workouts.splice(f, 1)
                plan.update(nplan)
            }
        )
    }

    function Create(w: Workout) {
        if (!plan.selected) return
        const newWorkout = { ...w } as Workout
        const nplan = { ...plan.selected }

        newWorkout.id = md5(nanoid() + Date.now())

        nplan.workouts.push(newWorkout)
        plan.update(nplan)
    }

    async function ImportWorkout(id: string) {
        if (props.create) props.handleCreate()

        await api
            .post("workout-templates/get", { id: id })
            .then((res) => {
                const template = res.data[0] as Workout
                Create(template)
            })
            .catch((res) => {
                mostrarAlerta(res)
            })
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event

        if (!plan.selected || !over) return
        const updatedPlan = { ...plan.selected }

        if (active.id !== over?.id) {
            setLocalWorkouts((prevWorkouts) => {
                const oldIndex = prevWorkouts.findIndex(
                    (it) => it.id === active.id
                )
                const newIndex = prevWorkouts.findIndex(
                    (it) => it.id === over.id
                )

                updatedPlan.workouts = arrayMove(
                    prevWorkouts,
                    oldIndex,
                    newIndex
                )
                plan.update(updatedPlan)

                return updatedPlan.workouts
            })
        }
    }

    function saveChanges() {
        if (!plan.selected) return

        const updatedPlan = { ...plan.selected }
        updatedPlan.workouts = localWorkouts
        plan.update(updatedPlan)
    }

    const sensors = useSensors(
        useSensor(SmartSensor.sensor, SmartSensor.activation)
    )

    if (!plan.selected) return <></>

    return (
        <>
            <DndContext
                autoScroll={{ layoutShiftCompensation: false }}
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToHorizontalAxis]}
            >
                <SortableContext
                    items={localWorkouts ?? []}
                    strategy={horizontalListSortingStrategy}
                >
                    <List>
                        <Tab
                            onClick={handleCreate}
                            className="column"
                            selected={props.create}
                            style={{
                                width: 60,
                                justifyContent: "center",
                            }}
                        >
                            <TbFilePlus size={22} />

                            <p style={{ fontWeight: "bold", fontSize: 12 }}>
                                CRIAR
                            </p>
                        </Tab>

                        <Tab
                            className="column"
                            onClick={() => setImport(!importTemplate)}
                            selected={importTemplate}
                            style={{
                                width: 75,
                                justifyContent: "center",
                                paddingInline: 10,
                            }}
                        >
                            <TbFileImport size={22} />
                            <p style={{ fontWeight: "bold", fontSize: 12 }}>
                                IMPORTAR
                            </p>
                        </Tab>
                        <TemplatesBrowser
                            active={importTemplate}
                            type="Workout"
                            headerTitle="Importar Treino Template (Cópia)"
                            onSelect={(e, i) => ImportWorkout(i)}
                            onDismis={setImport}
                        />

                        {localWorkouts.map((e) => (
                            <DraggableTab
                                key={e.id}
                                modified={
                                    localStorage.getItem(getTag(e.id)) !== null
                                }
                                content={e}
                                onClick={() => handleSelect(e)}
                                onDelete={Delete}
                                onDuplicate={Create}
                            />
                        ))}
                    </List>
                </SortableContext>
            </DndContext>
        </>
    )
}

export default Tabs
