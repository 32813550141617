import moment from "moment"
import React from "react"
import styled from "styled-components"

interface SubscriptionHistoryItem {
    type: "subscription"
    planName: string // Nome do plano
    id: string // ID da assinatura
    status: string // Status da assinatura (ex: "active", "canceled")
    canceledAt: number | null // Timestamp de cancelamento, se aplicável
    cancelAtPeriodEnd: boolean // Indica se a assinatura será cancelada ao final do período
    currentPeriodEnd: number // Timestamp do final do período atual
    date: number // Data de referência para ordenação cronológica
    amount: number // Valor do plano
}

interface PaymentHistoryItem {
    type: "payment"
    id: string // ID do pagamento (fatura)
    amountPaid: number // Valor pago
    currency: string // Moeda usada para o pagamento
    paidAt: number // Timestamp do pagamento efetuado
    subscription: string // ID da assinatura associada ao pagamento
    date: number // Data de referência para ordenação cronológica
    paymentType: string // Tipo de pagamento (PRIMEIRO PAGAMENTO ou RENOVADO)
}

export type HistoryItem = SubscriptionHistoryItem | PaymentHistoryItem

const Status = styled.p<{ color: string }>`
    background-color: ${(p) => (p.color ?? "#ddd") + "88"};
    border: 1px solid ${(p) => p.color ?? "#ddd"};
    color: ${(p) => p.color ?? "#ddd"};
    font-size: 10px;
    font-weight: 400;
    border-radius: 25px;
    padding: 0px 8px;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 5px;
`

const Strong = styled.strong`
    font-weight: 600;
    text-transform: uppercase;
    font-size: 10px;
    padding: 2px;
    border-radius: 2px;
    margin-right: 10px;
    color: #777;
`

const HistoryItemContainer = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    justify-content: space-between;
`

const SubscriptionDetails = styled.div`
    display: flex;
    flex-direction: column;

    align-items: end;

    h5 {
        font-size: 10px;
        color: #444;
        font-weight: bold;
    }
`

const Col = styled.div<{ flex?: number }>`
    flex: ${(p) => p.flex ?? 0};
    flex-direction: column;
`

// Helper function to anonymize the ID
function cut(value: string) {
    const v = value.split("_")[1]
    return v.substring(0, 4) + "***" + v.substring(v.length - 4)
}

// Componente para renderizar um item do histórico
const HistoryItemComponent: React.FC<{ item: HistoryItem }> = ({ item }) => {
    if (item.type === "subscription") {
        return (
            <HistoryItemContainer>
                <Col>
                    <p>
                        <Strong>{cut(item.id)}</Strong>
                    </p>
                </Col>

                <Col flex={1}>
                    <p> Assinatura do plano</p>
                    <span style={{ margin: 2 }} />
                    <p>Valor do plano: R$ {(item.amount / 100).toFixed(2)}</p>
                </Col>

                <SubscriptionDetails>
                    <Status color={item.status === "active" ? "#0b0" : "#f00"}>
                        {item.status.toUpperCase()}
                    </Status>
                    {item.canceledAt ? (
                        <h5>
                            {moment(new Date(item.canceledAt * 1000)).format(
                                "L, HH:mm"
                            )}
                        </h5>
                    ) : (
                        <h5>
                            <strong>ATÉ </strong>
                            {moment(
                                new Date(item.currentPeriodEnd * 1000)
                            ).format("L, HH:mm")}
                        </h5>
                    )}
                </SubscriptionDetails>
            </HistoryItemContainer>
        )
    } else if (item.type === "payment") {
        return (
            <HistoryItemContainer>
                <p>
                    <Strong>{cut(item.subscription)}</Strong>PAGAMENTO{" "}
                    {(item.amountPaid / 100).toFixed(2)}{" "}
                    {item.currency.toUpperCase()}
                </p>
                <SubscriptionDetails>
                    <Status color="#444">{item.paymentType}</Status>
                    <h5>
                        {moment(new Date(item.paidAt * 1000)).format(
                            "L, HH:mm"
                        )}
                    </h5>
                </SubscriptionDetails>
            </HistoryItemContainer>
        )
    }
    return <p>Tipo de item desconhecido</p>
}

export default HistoryItemComponent
