const plans = [
    {
        title: "Gestão dos Treinos",
        description:
            "Desenvolva treinos personalizados e otimizados para cada aluno, com controle total sobre séries, repetições e exercícios, garantindo resultados mais eficientes.",
        available: false,
    },
    {
        title: "Gestão das Avaliações",
        description:
            "Realize avaliações antropométricas detalhadas para acompanhar o progresso físico de seus alunos e ajustar planos de treinamento e nutrição de forma mais precisa.",
        available: false,
    },
    {
        title: "Análise de Progressão de Carga",
        description:
            "Monitore a evolução de desempenho dos alunos em cada treino, com dados detalhados sobre o aumento de carga e progresso ao longo do tempo.",
        available: false,
    },
]

export default plans
