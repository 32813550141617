import { AxiosRequestConfig } from "axios"

const local = false

const config = {
    baseURL: local
        ? "http://192.168.100.6:3035"
        : "https://test.fithub.app.br/", //http://127.0.0.1:22795/
    timeout: 10000,
} as AxiosRequestConfig

export default config
