import React from "react"
import { Section, Wrapper } from "../styles"
import { useQuery } from "react-query"
import api from "src/rest/api"
import { ItemList, List } from "./styles"
import HistoryItemComponent, { HistoryItem } from "./item"
import { LineWave, ThreeDots } from "react-loader-spinner"
import styled from "styled-components"
import { CgCheck } from "react-icons/cg"
import { FiAlertOctagon } from "react-icons/fi"
import { GiHandOk } from "react-icons/gi"
import { BiMoney } from "react-icons/bi"
import { FaMoneyCheck } from "react-icons/fa"

export const Centered = styled.div<{ error?: boolean }>`
    justify-content: center;
    align-items: center;

    color: ${(p) => (p.error ? "red" : "#444")};

    flex-direction: column;
`

const BillingHistory: React.FC = () => {
    const { data, isError, isLoading } = useQuery("listHistory", () =>
        api
            .post<HistoryItem[]>("/stripe/list-history")
            .then((res) => res.data)
            .catch(() => undefined)
    )

    const HistoryList = () => {
        if (data && !isLoading) {
            if (data.length > 0)
                return (
                    <>
                        <h4>Transações</h4>

                        {data &&
                            data.slice(0, 5).map((e, i) => (
                                <ItemList key={"hs_" + i}>
                                    <HistoryItemComponent item={e} />
                                </ItemList>
                            ))}
                    </>
                )
            else
                return (
                    <Centered>
                        <FaMoneyCheck />
                        <p>Você não possui histórico de transações!</p>
                    </Centered>
                )
        } else if (isError) {
            return (
                <Centered error>
                    <FiAlertOctagon />
                    <p>Erro ao listar transações</p>
                </Centered>
            )
        } else {
            return (
                <Centered>
                    <ThreeDots height={10} color="#444" />
                </Centered>
            )
        }
    }

    return (
        <Wrapper>
            <h2>Histórico</h2>
            <h4>Dados de faturamento da sua licença</h4>
            <span style={{ margin: 10 }} />
            <Section>
                <List>{HistoryList()}</List>
            </Section>
        </Wrapper>
    )
}

export default BillingHistory
