import React, { useEffect, useState } from "react"

import {
    Workout,
    WorkoutTypeString,
    WorkoutIcon,
} from "../../../../../fithub-shared/interfaces/workout"
import EditableText from "../../../../../../components/editable-text"
import MutableIcon from "../../../../../../components/mutable-icon"
import { Header, Labels, ModText } from "./styles"
import useLoadingButton from "src/modules/assessment/useLoadingButton"
import { BiUndo } from "react-icons/bi"
import {
    MdCheck,
    MdClose,
    MdDeleteForever,
    MdRemoveCircle,
} from "react-icons/md"
import { isMobile } from "react-device-detect"

interface HeaderProps {
    handleSave: () => Promise<void>
    workout: Workout
    RenameWorkout: (t: string) => void
    modified: boolean
    unsetModified: () => void
    tag: string
    onRemove: () => void
}

const SaveButton: React.FC<{
    handleSave: () => Promise<void>
}> = ({ handleSave }) => {
    const button = useLoadingButton(
        () => {
            handleSave().then(() => button.changeState("LOADED"))
        },
        1,
        "UNLOADED"
    )

    return <div>{button.Button}</div>
}

export const WorkoutHeaderTemplate = (props: HeaderProps) => {
    const [workout, setWorkout] = useState<Workout>()

    useEffect(() => {
        if (props.workout.id !== workout?.id) {
            setWorkout(undefined)
            setTimeout(() => setWorkout(props.workout), 5)
        }
    }, [props.workout])

    const CacheInfo = () => {
        if (isMobile || window.screen.width < 768) {
            return (
                <>
                    {props.modified ? (
                        <MdClose color="var(--primary)" size={12} />
                    ) : (
                        <MdCheck size={12} />
                    )}
                </>
            )
        } else {
            return <>{props.modified ? "Modificado" : "Sem Modificações"}</>
        }
    }

    if (!workout) return <></>
    return (
        <Header>
            <div
                style={{
                    alignItems: "center",
                }}
            >
                {/*<MutableIcon
                    icon={HiChevronLeft}
                    size={40}
                    onClick={planContext.workout.unselect}
                />*/}
                <MutableIcon
                    icon={WorkoutIcon(props.workout.type)}
                    size={28}
                    color={"#0008"}
                />
                <Labels>
                    <EditableText
                        onChange={props.RenameWorkout}
                        defaultValue={workout?.title as string}
                    />
                    <h5>{WorkoutTypeString(workout?.type)}</h5>
                </Labels>
            </div>

            <div className="flex row" style={{ alignItems: "center" }}>
                {/*workout.history && (
                    <>
                        <MutableIcon
                            icon={BiUndo}
                            disabled={!workout.history.canUndo}
                            size={36}
                            onClick={workout.history.undo}
                        />
                        <MutableIcon
                            icon={BiRedo}
                            disabled={!workout.history.canRedo}
                            size={36}
                            onClick={workout.history.redo}
                        />
                    </>
                )*/}
                <div className="flex row" style={{ alignItems: "center" }}>
                    <ModText
                        modified={props.modified}
                        onClick={() => {
                            alert("Função Indisponível")
                        }}
                    >
                        {props.modified && (
                            <>
                                <BiUndo size={20} />

                                <span style={{ width: 4 }} />
                            </>
                        )}
                        <CacheInfo />
                    </ModText>

                    <SaveButton handleSave={props.handleSave} />
                </div>
                <span style={{ marginLeft: 10 }} />
                <MutableIcon
                    onClick={() => {
                        props.onRemove()
                    }}
                    icon={MdDeleteForever}
                    size={28}
                    color={"#444"}
                />
            </div>
        </Header>
    )
}

export default WorkoutHeaderTemplate
