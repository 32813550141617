import React, { useState } from "react"
import { Wrap } from "./styles"
import { IconType } from "react-icons"
import MutableIcon from "../mutable-icon"
import { MdEdit } from "react-icons/md"
// import { Container } from './styles';

const EditableText: React.FC<{
    defaultValue: string
    fontSize?: number
    fontWeight?: string
    onChange?: (e: string) => void
    icon?: IconType
    className?: string
}> = (props) => {
    const [editMode, setEditMode] = useState(false)
    const [currentValue, setCurrentValue] = useState(
        props.defaultValue as string
    )

    function onBlur(e: React.FocusEvent<HTMLInputElement>) {
        Conclude(e.currentTarget.value)
        setEditMode(false)
    }

    function Conclude(t: string) {
        if (t.length == 0) return

        if (props.onChange !== undefined) {
            props.onChange(t)
        }
        setCurrentValue(t)

        setEditMode(false)
    }

    function keyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") Conclude(e.currentTarget.value)
    }

    if (editMode) {
        return (
            <Wrap className={props.className}>
                <input
                    width={1000}
                    autoCorrect=""
                    onBlur={onBlur}
                    onKeyDown={keyDown}
                    maxLength={40}
                    autoFocus
                    onFocus={(e) => e.target.select()}
                    defaultValue={currentValue}
                    style={{
                        fontWeight: props.fontWeight,
                        fontSize: props.fontSize,
                    }}
                ></input>
            </Wrap>
        )
    } else {
        return (
            <Wrap onClick={() => setEditMode(true)}>
                {props.icon && <MutableIcon icon={props.icon} />}
                <h4>{currentValue}</h4>
                <MutableIcon icon={MdEdit} size={14} />
            </Wrap>
        )
    }
}

export default EditableText
