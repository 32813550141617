import React, { useRef, useState } from "react"

import { Avatar } from "../../components/header/styles"
import { Item, List, ListHeader, Scroll, SearchField } from "./styles"

import Inputfield from "../../components/inputfield"

import { RiSearch2Line } from "react-icons/ri"
import { HiChevronLeft } from "react-icons/hi"
import MutableIcon from "../../components/mutable-icon"
import api from "src/rest/api"
import { MdPerson, MdPersonAdd } from "react-icons/md"
import CreateForm from "./create-form"
import { FilterAndSort } from "./functions"
import { useStudent } from "src/contexts/student"
import { ThreeDots } from "react-loader-spinner"
import { useQuery } from "react-query"
import { EmptyList } from "src/modules/protocols/Plan/View/protocols-listing/styles"
import EmptyListInfo from "src/modules/protocols/components/EmptyListInfo"
import { IconBaseProps } from "react-icons"
import Students from "./list"
import { CenteredDiv } from "../calendar/styling/styles"

interface StudentsListProps {
    dismiss: () => void
    onSelect: (id: StudentList) => void
}

export interface StudentList {
    id: string
    name: string
    username: string
    gender: string
    birthdate: string
    photourl: string
    status: "blocked" | "active"
}

export default function StudentsList(props: StudentsListProps) {
    const listing = useQuery("Students", () =>
        api
            .post<StudentList[]>("/students/list")
            .then((res) => res.data)
            .catch(() => undefined)
    )

    const ref = useRef<HTMLDivElement>(null)
    const [create, setCreate] = useState(false)
    const [filter, setFilter] = useState("")

    const filtered = FilterAndSort(filter, listing.data)

    const student = useStudent()

    function ToggleCreate() {
        setCreate(true)
    }

    function onGoBack() {
        if (create) {
            listing.refetch()
            setCreate(false)
        }

        props.dismiss()
    }

    function onAdd() {
        setCreate(false)
        listing.refetch()
    }

    function OnEditTitle(t: string) {
        return t
    }

    function StudentListComp() {
        if (listing.isLoading) {
            return (
                <div
                    className="flex"
                    style={{
                        flex: 1,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ThreeDots height={60} width={60} color="black" />
                </div>
            )
        } else if (!listing.data) {
            return (
                <CenteredDiv style={{ flexDirection: "column", opacity: 0.7 }}>
                    <MdPerson />
                    <h5>Erro ao carregar alunos</h5>
                    <button
                        style={{ cursor: "pointer", margin: 10, padding: 5 }}
                        onClick={() => window.location.reload()}
                    >
                        RECARREGAR
                    </button>
                </CenteredDiv>
            )
        } else if (listing.data?.length === 0)
            return (
                <CenteredDiv style={{ flexDirection: "column", opacity: 0.5 }}>
                    <MdPerson />
                    <h5>Você não possui alunos cadastrados!</h5>
                </CenteredDiv>
            )

        return <Students list={filtered} onSelect={props.onSelect} />
    }

    return (
        <List ref={ref}>
            <>
                {create ? (
                    <CreateForm onGoBack={onGoBack} onAdd={onAdd} />
                ) : (
                    <div
                        ref={ref}
                        style={{ flexDirection: "column", height: "100%" }}
                    >
                        <ListHeader>
                            <MutableIcon
                                icon={HiChevronLeft}
                                size={40}
                                onClick={onGoBack}
                            />
                            <SearchField>
                                {create ? (
                                    <>
                                        <h3>Cadastrar Aluno</h3>
                                    </>
                                ) : (
                                    <>
                                        <h3>Alunos</h3>
                                        <Inputfield
                                            icon={RiSearch2Line}
                                            radius={"25px"}
                                            width={"100%"}
                                            onEndText={OnEditTitle}
                                            onChangeText={setFilter}
                                            inputProps={{
                                                placeholder:
                                                    "Pesquisar por aluno...",
                                            }}
                                        />
                                    </>
                                )}
                            </SearchField>
                            <MutableIcon
                                disabled={create}
                                icon={MdPersonAdd}
                                size={40}
                                onClick={ToggleCreate}
                            />
                        </ListHeader>
                        <Scroll>
                            <StudentListComp />
                        </Scroll>
                    </div>
                )}
            </>
        </List>
    )
}
