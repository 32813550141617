import React from "react"

import { Container } from "./styles"

import CurrentPlan from "./CurrentPlan"
import BillingHistory from "./BillingHistory"

const Checkout: React.FC = () => {
    return (
        <Container className="flex">
            <CurrentPlan />
            <span style={{ margin: 10 }} />
            <BillingHistory />
        </Container>
    )
}

export default Checkout
