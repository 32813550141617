import React, { CSSProperties, InputHTMLAttributes, useState } from "react"
import { IconType } from "react-icons"
import { Area } from "../styles"
import styled from "styled-components"

export const VerticalWrapper = styled.div<{ width?: number | string }>`
    flex: 1;
    width: ${(p) => p.width && p.width};
    height: 45px;
    flex-shrink: 0;
    flex-direction: column;
`

interface MaskConfig {
    pattern: string
    allow: string[] // Caracteres permitidos (ex.: ["n*", "+"])
}

interface InputfieldMaskProps {
    error?: boolean
    style?: CSSProperties
    icon?: IconType
    width?: number | string
    radius?: string
    backgroundColor?: string
    inputProps?: InputHTMLAttributes<HTMLInputElement>
    onChangeText?: (value: string) => void
    onEndText?: (value: string) => void
    hint?: string
    mask?: MaskConfig
}

const InputfieldMask: React.FC<InputfieldMaskProps> = (props) => {
    const [error, setError] = useState(props.error)
    const [isMasked, setIsMasked] = useState(true) // Controla se a máscara está ativa
    const [inputValue, setInputValue] = useState("")

    // Função para aplicar máscara configurável
    const applyCustomMask = (value: string, maskConfig: MaskConfig): string => {
        const { pattern, allow } = maskConfig
        const allowedCharacters = allow
            .map((char) => (char === "n*" ? "\\d" : char))
            .join("")
        const regex = new RegExp(`[^${allowedCharacters}]`, "g")

        // Remove todos os caracteres que não estão no allow
        const cleaned = value.replace(regex, "")

        let maskedValue = ""
        let cleanedIndex = 0

        // Itera pelo padrão e substitui placeholders por caracteres
        for (let i = 0; i < pattern.length; i++) {
            if (pattern[i] === "#") {
                if (cleaned[cleanedIndex]) {
                    maskedValue += cleaned[cleanedIndex]
                    cleanedIndex++
                } else {
                    break
                }
            } else {
                // Adiciona o caractere da máscara se houver algum caractere preenchido ou já existirem entradas
                if (cleanedIndex > 0 || maskedValue.length < value.length) {
                    maskedValue += pattern[i]
                }
            }
        }

        return maskedValue
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value

        // Aplica a máscara se estiver configurada e o campo estiver com a máscara ativa
        const maskedValue =
            props.mask && isMasked
                ? applyCustomMask(rawValue, props.mask)
                : rawValue

        setInputValue(maskedValue)

        // Executa callbacks, se existirem
        if (props.onChangeText) props.onChangeText(maskedValue)
        if (props.inputProps?.onChange) props.inputProps.onChange(e)
    }

    const handleOnBlur = () => {
        setIsMasked(true) // Ativa a máscara ao sair do campo

        // Aplica a máscara no valor final
        if (props.mask && inputValue) {
            const maskedValue = applyCustomMask(inputValue, props.mask)
            setInputValue(maskedValue)
            if (props.onEndText) props.onEndText(maskedValue)
        } else {
            if (props.onEndText) props.onEndText(inputValue)
        }
    }

    const handleOnFocus = () => {
        setIsMasked(false) // Desativa a máscara ao focar no campo
    }

    return (
        <VerticalWrapper width={props.width}>
            {props.hint && (
                <p
                    style={{
                        width: "100%",
                        fontSize: 12,
                        paddingBottom: 5,
                    }}
                >
                    {props.hint}
                </p>
            )}
            <Area
                tabIndex={-1}
                radius={props.radius}
                style={{
                    ...{
                        flexDirection: "row",
                        paddingLeft: props.icon ? 0 : 5,
                        opacity: props.inputProps?.disabled ? 0.5 : 1,
                        backgroundColor: props.backgroundColor || "#fff",
                        borderColor: error ? "red" : "#ccc",
                        flexShrink: 0,
                    },
                    ...props.style,
                }}
            >
                {props.icon && (
                    <div style={{ marginInline: 5 }}>
                        <props.icon color={"#444"} size={18} />
                    </div>
                )}
                <input
                    {...props.inputProps}
                    style={{
                        ...props.inputProps?.style,
                        paddingInline: 5,
                        fontSize: props.style?.fontSize,
                    }}
                    type="text" // Força o tipo de entrada para texto
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                    value={inputValue}
                />
            </Area>
        </VerticalWrapper>
    )
}

export default InputfieldMask
