import { User } from "firebase/auth"
import Cookies from "js-cookie"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import api, { altToken } from "src/rest/api"

import { ResponseUser, userData } from "src/assets/caching/user-cache"
import { Storage } from "../modules/fithub-shared/TokenManager/data-storage"

interface GUser {
    user: User | null
    setUser: (value: User | null) => void
}

interface AuthContextData {
    signed: boolean
    user: userData | undefined
    Login(user: string, password: string): Promise<string>
    LoginGoogle(email: string, photoURL: string): Promise<number>
    Logout(): void
    GSignIn: GUser
}

const AuthContext = createContext({} as AuthContextData)

interface Props {
    signedComponent: React.ReactNode
    unsignedComponent: React.ReactNode
}

const TAG = "@auth:user"

export const AuthProvider: React.FC<Props> = ({
    signedComponent,
    unsignedComponent,
}) => {
    const nav = useNavigate()

    const [guser, setGUser] = useState<User | null>(null)

    const [user, setUser] = useState(Storage.get<userData>(TAG))

    useEffect(() => {
        if (!user) {
            const user = Storage.renew(TAG)
            if (!user) Logout()
        }
    }, [])

    function defineUser(user: ResponseUser) {
        const data = user as userData
        Storage.set(TAG, data)
        setUser(user)

        const { access, refresh } = user.tokens

        altToken.SetTokens({ access, refresh })
    }

    function LoginGoogle(email: string, photoURL: string): Promise<number> {
        return new Promise((resolve, reject) => {
            api.post<ResponseUser>(`/login/google`, {
                email,
                photoURL,
            })
                .then((res) => {
                    try {
                        if (res.status === 200) defineUser(res.data)
                    } finally {
                        resolve(res.status)
                    }
                })
                .catch((err) => {
                    reject(400)
                })
        })
    }

    function Login(user: string, password: string): Promise<string> {
        return new Promise((resolve, reject) => {
            api.post<ResponseUser>(`/login`, {
                email: user,
                password: password,
            })
                .then((res) => {
                    try {
                        defineUser(res.data)
                    } finally {
                        resolve("Ok")
                    }
                })
                .catch((err) => {
                    reject(`${err.response.data.message}`)
                })
        })
    }
    async function Logout() {
        Storage.remove(TAG)

        setUser(undefined)

        nav("/")
    }

    return (
        <AuthContext.Provider
            value={{
                signed: Boolean(user),
                user,
                Login,
                LoginGoogle,
                Logout,
                GSignIn: { user: guser, setUser: setGUser },
            }}
        >
            {Boolean(user) && user ? signedComponent : unsignedComponent}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error("useAuth deve ser usado dentro de AuthProvider")
    }
    return context
}

export default AuthContext
